import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import axios from "axios";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { history } from "../../../../../Shared/Types/items";
import { log } from "../../../../../Shared/Types/log";
import { useGlobalState } from "../../../services/auth.service";
import { dateStandard } from "../../../services/date.service";
import { Loader } from "../../common/loader/loader";

export const HistoryTable = forwardRef((props: { id: number; location: string; perPage?: number; eventName?: string }, ref) => {
    const [history, setHistory] = useState<history[]>([]);
    const [historyRows, setHistoryRows] = useState(0);
    const [historyType, setHistoryType] = useState<"history" | "adjustments">("history");
    const [page, setPage] = useState(0);
    const [fetchedPages, setFetchedPages] = useState<number[]>([]);
    const [loading, setLoading] = useState(false);

    const { state } = useGlobalState();

    let perPage = props.perPage ?? 25;

    async function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        if (!fetchedPages.includes(newPage + 1)) {
            setLoading(true);
            let historyReq = await axios.get<{
                rows: number;
                data: history[];
            }>(`/api/${props.location}/${historyType}/${props.id}?page=${newPage + 1}`);
            let historyCopy = [...history];
            if (historyCopy) {
                historyCopy.push(...historyReq.data.data);
            } else if (historyReq.data.data.length > 0) {
                historyCopy = historyReq.data.data;
            }
            historyCopy = removeDuplicates(historyCopy);
            setHistory(historyCopy);
            setHistoryRows(historyReq.data.rows);
            setFetchedPages([...fetchedPages, newPage + 1]);
            setLoading(false);
        }
        setPage(newPage);
    }

    useEffect(() => {
        handleChangePage(null, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyType]);

    useEffect(() => {
        if (!state.ws) return;

        function orderHistoryUpdate(newLog: log) {
            if (newLog.changed_id === props.id) {
                let historyCopy = [...history];
                let log: history = {
                    id: newLog.id,
                    user_name: newLog.user.toString(),
                    property: newLog.property,
                    old_value: newLog.old_value,
                    new_value: newLog.new_value,
                    note: newLog.note ?? "-",
                    time: new Date(newLog.time)
                };
                historyCopy = [log, ...historyCopy];
                historyCopy = removeDuplicates(historyCopy);
                setHistory(historyCopy);
            }
        }

        const wsMessage = (msg: MessageEvent<any>) => {
            if (!props.eventName) return;
            try {
                let info = JSON.parse(msg.data);
                if (info[props.eventName]) orderHistoryUpdate(info[props.eventName]);
            } catch (error) {}
        };

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.ws, history]);

    function removeDuplicates(arr: any[]) {
        return arr.reduce(function (p, c) {
            if (!p.some((el: any) => el.id === c.id)) p.push(c);
            return p;
        }, []);
    }

    useImperativeHandle(ref, () => ({
        updateType(type: "history" | "adjustments") {
            updateHistoryType(type);
        }
    }));

    const updateHistoryType = (type: "history" | "adjustments") => {
        setPage(0);
        setFetchedPages([]);
        setHistoryRows(0);
        if (history) {
            setHistory([]);
        }
        setHistoryType(type);
    };

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Property Updated</TableCell>
                    <TableCell>Old Value</TableCell>
                    <TableCell>New Value</TableCell>
                    <TableCell>Amount Shipped</TableCell>
                    <TableCell>Note</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Updated</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {loading ? (
                    <TableRow>
                        <TableCell colSpan={6}>
                            <Loader />
                        </TableCell>
                    </TableRow>
                ) : (
                    history
                        ?.map(historyRow => (
                            <TableRow key={historyRow.id}>
                                <TableCell>{historyRow.property}</TableCell>
                                <TableCell>{historyRow.old_value}</TableCell>
                                <TableCell>{historyRow.new_value}</TableCell>
                                <TableCell>{historyRow.property === "stock" && historyRow.note?.includes("shipped") ? Math.abs((parseFloat(historyRow.new_value) ?? 0) - (parseFloat(historyRow.old_value) ?? 0)) : "-"}</TableCell>
                                <TableCell>{historyRow.note ?? "-"}</TableCell>
                                <TableCell>{historyRow.user_name}</TableCell>
                                <TableCell>{dateStandard(new Date(historyRow.time))}</TableCell>
                            </TableRow>
                        ))
                        .slice(page * perPage, (page + 1) * perPage)
                )}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[perPage]}
                        colSpan={7}
                        count={historyRows ?? 0}
                        rowsPerPage={perPage}
                        page={page}
                        SelectProps={{
                            inputProps: {
                                "aria-label": "rows per page"
                            },
                            native: true
                        }}
                        onPageChange={handleChangePage}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    );
});
