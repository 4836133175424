/* eslint-disable no-restricted-globals */
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { charlieOrderQuery } from "../../../../Shared/Types/charlieOrders";
import { useGlobalState } from "../../services/auth.service";
import { dateOnly } from "../../services/date.service";
import { titleCase } from "../../services/generic.helper";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { SearchBar } from "../common/search/search";
import { toastType } from "../common/toast/toast";

export function CharlieOrdersPage() {
    const [error, setError] = useState<any>(null);
    const [search, setSearch] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [orders, setOrders] = useState<charlieOrderQuery[]>([]);
    const { state, setState } = useGlobalState();

    const [tableRows, setTableRows] = useState(0);
    const [page, setPage] = useState(0);
    const [fetchedPages, setFetchedPages] = React.useState<number[]>([]);

    useEffect(() => {
        async function getItems() {
            try {
                let orders = (
                    await axios.get<{
                        rows: number;
                        data: charlieOrderQuery[];
                    }>("/api/charlie")
                ).data;
                setIsLoaded(true);
                setOrders(orders.data);
                setTableRows(orders.rows);
            } catch (error) {
                setIsLoaded(true);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading orders",
                        type: toastType.Error
                    })
                );
                console.error(error);
            }
        }

        getItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!state.ws) return;

        const wsMessage = (msg: MessageEvent<any>) => {
            try {
                let info = JSON.parse(msg.data);
                if (info.recipe) updateRecipes(info.recipe);
            } catch (error) {}
        };

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders]);

    const updateRecipes = (newRecipe: charlieOrderQuery) => {
        // TODO - WS updates
    };

    async function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        if (!fetchedPages.includes(newPage)) {
            setIsLoaded(false);
            let orderReq = await axios.get<{
                rows: number;
                data: charlieOrderQuery[];
            }>(`/api/charlie?page=${newPage}`);
            let newOrders = [...orders, ...orderReq.data.data];
            setFetchedPages([...fetchedPages, newPage]);
            setOrders(newOrders);
            setTableRows(orderReq.data.rows);
            setIsLoaded(true);
        }
        setPage(newPage);
    }

    useEffect(() => {
        const timeoutId = setTimeout(async () => {
            try {
                let orders = (
                    await axios.get<{
                        rows: number;
                        data: charlieOrderQuery[];
                    }>(`/api/charlie?search=${search}`)
                ).data;
                setIsLoaded(true);
                setFetchedPages([0]);
                setPage(0);
                setOrders(orders.data);
                setTableRows(orders.rows);
            } catch (error) {
                setIsLoaded(true);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading orders",
                        type: toastType.Error
                    })
                );
                console.error(error);
            }
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const filterSearch = async (searchVal: string) => {
        setSearch(searchVal);
    };

    if (!isLoaded) {
        return (
            <div className="card">
                <Loader />
            </div>
        );
    } else if (error) {
        return <div className="card">Error: {error?.message}</div>;
    } else {
        return (
            <div className="card">
                <div className="itemHeader">
                    <h2>Orders</h2>
                </div>
                <SearchBar filterFunction={filterSearch} />
                <br />
                <br />
                <TableContainer>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[50]}
                                    colSpan={5}
                                    count={tableRows ?? 0}
                                    rowsPerPage={50}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page"
                                        },
                                        native: true
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </TableRow>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Dispatch Date</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders
                                .map(row => (
                                    <TableRow key={row.orderId} className={row.qa - row.q === 0 ? "" : "rowNegative"}>
                                        <TableCell>
                                            <Link
                                                to={{
                                                    pathname: `/charlie/${row.orderId}`
                                                }}
                                            >
                                                #{row.orderNumber}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{row.name ?? "-"}</TableCell>
                                        <TableCell>
                                            <span className={`badge-${row.orderStatus.replaceAll("_", "-")}`}>{titleCase(row.orderStatus.replaceAll("_", " ")) ?? "-"}</span>
                                            <span>
                                                {row.tags?.map(tag => (
                                                    <Tooltip title={tag.name} key={tag.name} arrow>
                                                        <i
                                                            className="tag"
                                                            style={{
                                                                backgroundColor: tag.color
                                                            }}
                                                        ></i>
                                                    </Tooltip>
                                                ))}
                                            </span>
                                        </TableCell>
                                        <TableCell>{dateOnly(new Date(row.dispatchDate))}</TableCell>
                                        <TableCell>
                                            <Tooltip
                                                title={
                                                    <span
                                                        style={{
                                                            whiteSpace: "pre-line"
                                                        }}
                                                    >
                                                        {row.skus}
                                                    </span>
                                                }
                                                placement="top"
                                            >
                                                <span className="material-icons">help_outline</span>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                                .slice(page * 50, (page + 1) * 50)}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[50]}
                                    colSpan={5}
                                    count={tableRows ?? 0}
                                    rowsPerPage={50}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page"
                                        },
                                        native: true
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}
