/* eslint-disable no-restricted-globals */
import { Button, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { recipe } from "../../../../Shared/Types/recipe";
import { useGlobalState } from "../../services/auth.service";
import { dateStandard } from "../../services/date.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { SearchBar } from "../common/search/search";
import { toastType } from "../common/toast/toast";
import { CreateRecipe } from "./createRecipe";

export function RecipePage() {
    const [error, setError] = useState<any>(null);
    const [search, setSearch] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [recipes, setRecipes] = useState<recipe[]>([]);
    type recipeSort = recipe & Required<"description">;
    const [filteredRecipes, setFilteredRecipes] = useState<recipe[]>([]);
    const [modalOpen, setModalOpen] = React.useState(false);
    const { state, setState } = useGlobalState();

    useEffect(() => {
        async function getItems() {
            try {
                let recipes: recipe[] = (await axios.get<recipe[]>("/api/recipes")).data;
                recipes.sort((a, b) => {
                    if (a.sku < b.sku) return -1;
                    if (a.sku > b.sku) return 1;
                    return 0;
                });
                setIsLoaded(true);
                setRecipes(recipes);
                setFilteredRecipes(recipes);
            } catch (error) {
                setIsLoaded(true);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading items",
                        type: toastType.Error
                    })
                );
                console.error(error);
            }
        }

        getItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!state.ws) return;

        const wsMessage = (msg: MessageEvent<any>) => {
            try {
                let info = JSON.parse(msg.data);
                if (info.recipe) updateRecipes(info.recipe);
                if (info.recipeDeleted) deleteRecipe(info.recipeDeleted);
            } catch (error) {}
        };

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipes]);

    const updateRecipes = (newRecipe: recipe) => {
        let updatedIndex = recipes.findIndex(x => x.id === newRecipe.id);
        let newItems = [...recipes];
        if (updatedIndex > -1) {
            newItems[updatedIndex] = newRecipe;
        } else {
            console.log("yeye");
            newItems.push(newRecipe);
        }
        newItems.sort((a, b) => {
            if (a.sku < b.sku) return -1;
            if (a.sku > b.sku) return 1;
            return 0;
        });
        setRecipes(newItems);
        filterSearch(search, newItems);
    };

    const deleteRecipe = (recipeId: string) => {
        let newRecipes = [...recipes];
        newRecipes = newRecipes.filter(x => x.id !== parseInt(recipeId));
        setRecipes(newRecipes);
        filterSearch(search, newRecipes);
    };

    const openNew = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    function filterSearch(searchVal: string, newRecipes = recipes) {
        setSearch(searchVal);
        searchVal = searchVal.toLowerCase();
        if (searchVal === "") {
            setFilteredRecipes(newRecipes);
            return;
        }
        setFilteredRecipes(newRecipes.filter(x => x.id.toString().toLowerCase().includes(searchVal) || x.description?.toLowerCase().includes(searchVal) || x.sku.toLowerCase().includes(searchVal)));
    }

    // table sorting
    const headCells: { id: keyof recipeSort; label: string }[] = [
        { id: "sku", label: "SKU" },
        { id: "description", label: "Description" },
        { id: "items", label: "Items" },
        { id: "modified", label: "Modified" }
    ];

    const [orderBy, setOrderBy] = React.useState<string>("sku");
    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof recipeSort) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property.toString());
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator<Key extends keyof any>(order: Order, orderBy: string): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy as Key) : (a, b) => -descendingComparator(a, b, orderBy as Key);
    }

    function stableSort<T>(array: T[], comparator: (a: any, b: any) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    if (!isLoaded) {
        return (
            <div className="card">
                <Loader />
            </div>
        );
    } else if (error) {
        return <div className="card">Error: {error?.message}</div>;
    } else {
        return (
            <div className="card">
                <div className="itemHeader">
                    <h2>Recipes</h2>
                    <div>
                        <Button color="primary" onClick={openNew} startIcon={<span className="material-icons">add</span>}>
                            Create Recipe
                        </Button>
                    </div>
                </div>
                <SearchBar filterFunction={filterSearch} />
                <br />
                <br />
                <TableContainer>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                {headCells.map(headCell => (
                                    <TableCell key={headCell.id.toString()} sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={(event: any) => handleRequestSort(event, headCell.id)}>
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort<recipeSort>(
                                filteredRecipes.map(x => {
                                    let tmp: Partial<recipe> = { ...x };
                                    tmp.modified = tmp.modified?.toString();
                                    if (!tmp.description) tmp.description = "";
                                    return tmp as recipeSort;
                                }),
                                getComparator(order, orderBy)
                            ).map(row => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <Link
                                            to={{
                                                pathname: `/recipe/${row.id}`
                                            }}
                                        >
                                            {row.sku}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{row.description ?? "-"}</TableCell>
                                    <TableCell>{row.items.map(x => x.sku).join(", ")}</TableCell>
                                    <TableCell className="no-wrap">{dateStandard(new Date(row.modified))}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog open={modalOpen}>
                    <CreateRecipe closeDialog={handleClose} />
                </Dialog>
            </div>
        );
    }
}
