import { Button, InputAdornment, TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { authCon, checkedUser, useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";
import "./login.scss";

export function Login() {
    const { state, setState } = useGlobalState();
    const [formState, setFormState] = useState({
        username: "",
        password: "",
        loading: false
    });

    function handleInputChange(event: { target: any }) {
        const target = event.target;
        const value: string = target.value;
        const name = target.name;
        setFormState({
            ...formState,
            [name]: value
        });
    }

    let submit = async (event: { preventDefault: any }) => {
        event.preventDefault();
        setFormState({
            ...formState,
            loading: true
        });
        try {
            let userReq = await axios.post("/api/auth/login", formState);
            setState({ ...state, ...{ user: userReq.data } });
        } catch (error) {
            console.error(error);
            setFormState({
                ...formState,
                loading: false
            });
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Incorrect email or password",
                    type: toastType.Error
                })
            );
        }
    };

    async function updateUser() {
        setFormState({
            ...formState,
            loading: true
        });
        let tmpUser = await authCon.getUser();
        setState({ ...state, ...{ user: tmpUser } });
        if (tmpUser === null) {
            setFormState({
                ...formState,
                loading: false
            });
        }
    }

    if (!state.user && !formState.loading && !checkedUser) {
        updateUser();
    }

    return (
        <div className="loginContainer">
            <div className="card loginCard">
                {formState.loading ? <Loader onTop={true} /> : ""}
                <h2>Login</h2>
                <form onSubmit={submit}>
                    <TextField
                        label="Email"
                        name="username"
                        fullWidth
                        value={formState.username}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <span className="material-icons">account_circle</span>
                                </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        name="password"
                        fullWidth
                        value={formState.password}
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <span className="material-icons">lock</span>
                                </InputAdornment>
                            )
                        }}
                    />
                    <br />
                    <Button type="submit" variant="contained" color="primary" disabled={!!formState.loading}>
                        Login
                    </Button>
                </form>
            </div>
        </div>
    );
}

// export class Login extends React.Component<
//     {},
//     { [name: string]: string | boolean }
// > {
//     constructor(props: {} | Readonly<{}>) {
//         super(props);
//         this.state = {
//             username: "",
//             password: "",
//             loading: false,
//         };

//         this.handleInputChange = this.handleInputChange.bind(this);
//     }

//     handleInputChange(event: { target: any }) {
//         const target = event.target;
//         const value: string = target.value;
//         const name = target.name;
//         this.setState({
//             [name]: value,
//         });
//     }

//     async submit(event: { preventDefault: any }) {
//         event.preventDefault();
//         this.setState({
//             loading: true,
//         });
//         try {
//             let userReq = await axios.post("/api/auth/login", this.state);
//             reducer({}, { type: "LOGIN", user: userReq.data });
//             console.log(userReq.data);
//         } catch (error) {
//             console.error(error);
//         }
//     }

//     render() {
//         return (
//             <div className="loginContainer">
//                 <div className="card loginCard">
//                     <h2>Login</h2>
//                     <form onSubmit={this.submit.bind(this)}>
//                         <TextField
//                             label="Email"
//                             name="username"
//                             fullWidth
//                             value={this.state.username}
//                             onChange={this.handleInputChange}
//                         />
//                         <TextField
//                             label="Password"
//                             type="password"
//                             name="password"
//                             fullWidth
//                             value={this.state.password}
//                             onChange={this.handleInputChange}
//                         />
//                         <br />
//                         <Button
//                             type="submit"
//                             variant="contained"
//                             color="primary"
//                             disabled={!!this.state.loading}
//                         >
//                             Login
//                         </Button>
//                     </form>
//                 </div>
//             </div>
//         );
//     }
// }
