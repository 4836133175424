import { useEffect, useState } from "react";
import { useGlobalState } from "../../../services/auth.service";
import axios from 'axios';
import { Reports } from "../../../../../Shared/Types/report";
import { AddToToast } from "../../../services/toast.service";
import { toastType } from "../../common/toast/toast";
import { Loader } from "../../common/loader/loader";
import "./itemRecipes.scss";
import { Link } from "react-router-dom";
import { SearchBar } from "../../common/search/search";
import { Item } from "./item";

export function ItemRecipe() {

    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState<Reports.IItem[]>([]);
    const [filtered, setFilteredData] = useState<Reports.IItem[]>([]);
    const [error, setError] = useState<any>();
    const { state, setState } = useGlobalState();

    const [, setSearch] = useState("");

    useEffect(() => {
        async function updateData() {
            setIsLoaded(false);
            try {
                let itemData = (
                    await axios.get<Reports.IItem[]>(`/api/report/item-recipe`)
                ).data;
                itemData.sort((a, b) => {
                    return b.recipes.length - a.recipes.length;
                });
                setFilteredData(itemData.filter(X => X.recipes.length > 0));
                setIsLoaded(true);
                setData(itemData);
            } catch (error) {
                setIsLoaded(true);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading orders",
                        type: toastType.Error,
                    })
                );
                console.error(error);
            }


        }
        updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function searchFilter(v: string) {
        setSearch(v);
        v = v.toLowerCase();
        let itemCpy = [...data];
        itemCpy = itemCpy.filter(x => x.recipes.length > 0);
        if (v) {
            itemCpy = itemCpy.filter(x => (x.sku.toLowerCase().includes(v) || 
                x.sku.toLowerCase().includes(v) ||
                x.recipes.some(y => y.sku?.toLowerCase().includes(v) || y.description?.toLowerCase().includes(v))
            ));
        }
        setFilteredData(itemCpy);
    }

    if (!isLoaded) {
        return (
            <div className="card">
                <Loader />
            </div>
        );
    } else if (error) {
        return <div className="card">Error: {error?.message}</div>;
    } else {
        return (
            <div className="card">
                <div className="itemHeader">
                    <h2>Item Recipes</h2>
                </div>
                <br />
                <h3>Items Without A Recipe</h3>
                <div className="items">
                    {data.filter(x => x.recipes.length === 0).map((item) => (
                        <div className="listItem" key={item.id}>
                            <Link to={`/item/${item.id}`}>
                                <h4>{item.sku}</h4>
                            </Link>
                            <p>{item.description}</p>
                        </div>
                    ))}
                </div>
                <br />
                <hr />
                <br />
                <h3>Items With Recipe(s)</h3>
                <SearchBar filterFunction={searchFilter} />
                <br />
                <div className="items">
                    {filtered.map((item) => (
                        <Item key={item.id} item={item} />
                    ))}
                </div>
            </div>
        )
    }

}