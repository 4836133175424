export function titleCase(str: string) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}

export function numToDecimal(num: number) {
    if (Math.round(num) !== num) {
        return num.toFixed(2);
    } else {
        return num;
    }
}

export function sumProperty(input: any[], key: string) {
    return input.reduce((a, b) => a + (b[key] || 0), 0);
}

export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;

export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const numFormat = (num: number) => new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(num);

export function sqlDate(date?: Date) {
    let d = date ? new Date(date) : new Date();
    return d.toISOString().slice(0, 19).replace('T', ' ');
}