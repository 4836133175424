/* eslint-disable no-restricted-globals */
import { Button, Dialog, Paper, Tab, Tabs, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { item } from "../../../../Shared/Types/items";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";

import { Autocomplete } from "@mui/lab";
import { recipe } from "../../../../Shared/Types/recipe";

import { useNavigate } from "react-router-dom";
import { Confirmation } from "../common/confirmationDialog/confirmation";
import { HistoryTable } from "../shared/history/history";
import { CreateRecipe } from "./createRecipe";
import "./recipe.scss";

export function RecipeDetail() {
    let { recipeId } = useParams<{ recipeId: string }>();

    const [loading, setLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openDuplicate, setOpenDuplicate] = useState(false);
    const [error, setError] = useState<any>(false);
    const { state, setState } = useGlobalState();
    const [recipe, setRecipe] = useState<recipe | null>(null);

    const [items, setItems] = useState<item[]>([]);
    const [selItem, setSelItem] = useState<item | null>(null);
    const [quantity, setQuantity] = useState<number>(0);
    let autocompleteRender = 1;

    let RouterHistory = useNavigate();

    useEffect(() => {
        async function getPurchaseOrders() {
            setLoading(true);
            try {
                let tmpRecipe: recipe = (await axios.get<recipe>("/api/recipe/" + recipeId)).data;
                setRecipe(tmpRecipe);
                let tmpItems: item[] = (await axios.get<item[]>("/api/items/all")).data;
                setItems(tmpItems.filter(x => !tmpRecipe?.items.map(x => x.id).includes(x.id)));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading recipe",
                        type: toastType.Error
                    })
                );
                console.error(error);
            }
        }

        getPurchaseOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipeId]);

    useEffect(() => {
        if (!state.ws) return;

        const wsMessage = (msg: MessageEvent<any>) => {
            try {
                let info = JSON.parse(msg.data);
                if (info.recipe) updateRecipe(info.recipe);
                if (info.recipeDeleted) recipeDeleted(info.recipeDeleted);
            } catch (error) {}
        };

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipe]);

    const recipeDeleted = (recipeId: string) => {
        if (parseInt(recipeId) === recipe?.id) {
            RouterHistory("/recipes");
            setState(
                AddToToast(state, {
                    title: "Warning",
                    description: "Recipe was deleted",
                    type: toastType.Warning
                })
            );
        }
    };

    function updateRecipe(newOrder: recipe) {
        if (newOrder.id === recipe?.id) {
            setRecipe(newOrder);
        }
    }

    const [value, setValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const addItem = () => {
        autocompleteRender += 1;

        if (!selItem) return;

        axios.put(`/api/recipe/addItem`, {
            itemId: selItem.id,
            recipeId: recipe?.id,
            quantity: quantity
        });

        let tmpItems = items.filter(x => x.id !== selItem.id);
        tmpItems.sort((a, b) => {
            if (a.sku < b.sku) return -1;
            if (a.sku > b.sku) return 1;
            return 0;
        });
        setQuantity(0);
        setSelItem(null);
        setItems(tmpItems);
    };

    const removeItem = (item: item) => {
        if (!item) return;

        axios.put(`/api/recipe/removeItem`, {
            itemId: item.id,
            recipeId: recipe?.id
        });

        let tmpItems = [...items, item];
        tmpItems.sort((a, b) => {
            if (a.sku < b.sku) return -1;
            if (a.sku > b.sku) return 1;
            return 0;
        });
        setQuantity(0);
        setSelItem(null);
        setItems(tmpItems);
    };

    const deleteInfo = {
        header: "Delete Recipe",
        text: "Are you sure you want to delete this recipe, it cannot be undone",
        confirmText: "Delete",
        cancelText: "Cancel",
        closeFunction: deleteRecipe
    };

    async function deleteRecipe(canDelete = false) {
        setOpenDelete(false);
        if (canDelete && recipe) {
            try {
                await axios.delete(`/api/recipe/${recipe.id}`);
                setState(
                    AddToToast(state, {
                        title: "Success",
                        description: "Recipe Deleted",
                        type: toastType.Success
                    })
                );
                RouterHistory("/recipes");
            } catch (error) {
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "Recipe wasn't Deleted",
                        type: toastType.Error
                    })
                );
            }
        }
    }

    async function updateDescription() {
        if (!recipe) return;
        try {
            await axios.put(`/api/recipe/${recipe.id}/update-desc`, {
                desc: recipe.description
            });
            setState(
                AddToToast(state, {
                    title: "Info",
                    description: "Recipe Description Updated",
                    type: toastType.Info
                })
            );
        } catch (error) {
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Recipe wasn't updated",
                    type: toastType.Error
                })
            );
        }
    }

    if (loading || !recipe)
        return (
            <div className="card">
                <Loader />
            </div>
        );
    if (error) return <div className="card">Error: {error?.message}</div>;
    return (
        <div className="card">
            <div className="itemHeader">
                <h2>
                    Recipe #{recipeId} ({recipe?.sku} - {recipe?.description})
                </h2>
            </div>
            <br />
            <Paper>
                <Tabs centered value={value} onChange={handleChange}>
                    <Tab label="Details" />
                    <Tab label="History" />
                </Tabs>
            </Paper>
            <br />
            <div hidden={value !== 0}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                        value={recipe.description}
                        onChange={e =>
                            setRecipe({
                                ...recipe,
                                ...{ description: e.target.value }
                            })
                        }
                        style={{ marginLeft: "10px", flex: 1 }}
                        label="Recipe Description"
                    />
                    <Button variant="contained" color="primary" type="submit" onClick={updateDescription}>
                        Update
                    </Button>
                </div>
                <hr />
                <h4>Current Components:</h4>
                <br />
                <div className="components">
                    {recipe.items.map(row => (
                        <div key={row.id} title={row.description}>
                            {row.sku} x{row.quantity}
                            <span onClick={() => removeItem(row)} className="remove">
                                X
                            </span>
                        </div>
                    ))}
                </div>
                <br />
                <hr />
                <br />
                <h4>Add Components</h4>
                <div className="addComp">
                    <Autocomplete
                        key={autocompleteRender}
                        options={items}
                        // getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(option: any) => option.sku + " - " + (option.description ?? "")}
                        value={selItem}
                        onChange={(el, val) => setSelItem(val)}
                        style={{ width: 300 }}
                        renderInput={(params: any) => <TextField {...params} label="SKU" />}
                    />
                    <TextField autoFocus value={quantity} onChange={newVal => setQuantity(parseFloat(newVal.target.value))} label="Quantity" type="number" />
                    <Button variant="contained" color="secondary" type="submit" onClick={addItem}>
                        Add
                    </Button>
                </div>
            </div>
            <div hidden={value !== 1}>
                <HistoryTable id={recipe.id} location="recipe" perPage={25} ref={undefined} eventName="recipeHistory" />
            </div>
            <br />
            <hr />
            <br />
            <Button variant="outlined" className="dangerBtn" onClick={() => setOpenDelete(true)}>
                Delete
            </Button>
            &nbsp;
            <Button variant="outlined" color="secondary" onClick={() => setOpenDuplicate(true)}>
                Duplicate
            </Button>
            <Confirmation info={deleteInfo} isOpen={openDelete} />
            <Dialog open={openDuplicate}>
                <CreateRecipe closeDialog={() => setOpenDuplicate(false)} duplicate={recipe} />
            </Dialog>
        </div>
    );
}
