import { Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";

import { Autocomplete } from "@mui/lab";
import { Formik } from "formik";
import * as Yup from "yup";
import { item } from "../../../../Shared/Types/items";
import { purchaseOrder } from "../../../../Shared/Types/order";
import { supplier } from "../../../../Shared/Types/supplier";

export function EditOrder(props: { closeDialog: Function; order: purchaseOrder }) {
    let { closeDialog } = props;

    let initValues: Partial<purchaseOrder> & { loading: boolean } = {
        id: 0,
        supplierId: 0,
        itemId: 0,
        to_deliver: 0,
        cost: 0,
        note: "",
        fulfilled: "",
        loading: false
    };

    if (props.order.note === null) props.order.note = "";

    initValues = { ...initValues, ...props.order };

    const [formState, setFormState] = useState<Partial<purchaseOrder> & { loading: boolean }>({ ...initValues });
    const { state, setState } = useGlobalState();

    const [items, setItems] = useState<item[]>([]);
    const [suppliers, setSuppliers] = useState<supplier[]>([]);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        async function getExtraInfo() {
            try {
                let items: item[] = (await axios.get<item[]>("/api/items/all")).data;
                items.sort((a, b) => {
                    if (a.sku < b.sku) return -1;
                    if (a.sku > b.sku) return 1;
                    return 0;
                });
                setItems(items);
                let supp: supplier[] = (await axios.get<supplier[]>("/api/suppliers/all")).data;
                supp.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
                setSuppliers(supp);
                setRefresh(refresh + 1);
            } catch (error) {
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading items",
                        type: toastType.Error
                    })
                );
                console.error(error);
            }
        }
        getExtraInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleClose(update: any) {
        if (!update) return closeDialog(false);
        setFormState({
            ...formState,
            loading: true
        });
        try {
            delete update.loading;
            let item = await axios.put(`/api/order/update/${props.order.id}`, update);
            setState(
                AddToToast(state, {
                    title: "Success",
                    description: "Created Purchase Order",
                    type: toastType.Success
                })
            );
            closeDialog(item.data);
        } catch (error) {
            console.error(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Couldn't Create Purchase Order",
                    type: toastType.Error
                })
            );
            closeDialog(false);
        }
    }

    const formValidation = Yup.object().shape({
        supplierId: Yup.number().required("Required"),
        itemId: Yup.number().required("Required"),
        delivered: Yup.number().required("Required"),
        to_deliver: Yup.number().required("Required"),
        cost: Yup.number().required("Required"),
        note: Yup.string()
    });

    return (
        <React.Fragment>
            {formState.loading ? <Loader onTop={true} /> : ""}
            <DialogTitle>Edit Purchase Order</DialogTitle>
            <Formik initialValues={initValues} onSubmit={(values: any) => handleClose(values)} validationSchema={formValidation}>
                {(props: any) => {
                    const { values, touched, errors, isSubmitting, handleChange, handleSubmit, setFieldValue } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <Autocomplete
                                    options={suppliers}
                                    key={refresh + 1}
                                    // getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option: any) => suppliers.find(x => x.id === option)?.name ?? "-"}
                                    fullWidth
                                    value={values.supplierId}
                                    onChange={(_, value) => setFieldValue("supplierId", value?.id ?? 0)}
                                    renderInput={(params: any) => <TextField {...params} label="Supplier" />}
                                    disabled
                                />
                                <Autocomplete
                                    options={items}
                                    key={refresh}
                                    // getOptionSelected={(option, value) => option.id === +value}
                                    getOptionLabel={(option: any) => items.find(x => x.id === option)?.sku ?? "-"}
                                    fullWidth
                                    value={values.itemId}
                                    onChange={(_, value) => {
                                        setFieldValue("itemId", value?.id ?? 0);
                                        setFieldValue("to_deliver", value?.order_quantity ?? values.to_deliver);
                                    }}
                                    renderInput={(params: any) => <TextField {...params} label="Item" />}
                                    disabled
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="to_deliver"
                                    value={values.to_deliver}
                                    onChange={handleChange}
                                    helperText={errors.to_deliver && touched.to_deliver && errors.to_deliver}
                                    error={!!errors.to_deliver && !!touched.to_deliver}
                                    label="Item Quantity"
                                    type="text"
                                    disabled={!!values.sent}
                                    fullWidth
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="delivered"
                                    value={values.delivered}
                                    onChange={handleChange}
                                    helperText={errors.delivered && touched.delivered && errors.delivered}
                                    error={!!errors.delivered && !!touched.delivered}
                                    label="Quantity Delivered"
                                    type="text"
                                    fullWidth
                                />
                                <TextField autoFocus margin="dense" name="cost" value={values.cost} onChange={handleChange} helperText={errors.cost && touched.cost && errors.cost} error={!!errors.cost && !!touched.cost} label="Total Price" type="text" fullWidth />
                                <TextField autoFocus margin="dense" name="note" value={values.note} onChange={handleChange} helperText={errors.note && touched.note && errors.note} error={!!errors.note && !!touched.note} label="Optional Note" type="text" fullWidth />
                                <FormControlLabel control={<Checkbox name="fulfilled" checked={!!values.fulfilled} onChange={handleChange} />} label="Fulfilled" />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button variant="contained" color="secondary" disabled={isSubmitting} type="submit">
                                    Update
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}
