/* eslint-disable no-restricted-globals */
import { Dialog, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { inventoryNew } from "../../../../Shared/Types/inventory";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { SearchBar } from "../common/search/search";
import { toastType } from "../common/toast/toast";
import { CreateOrder } from "../purchaseOrders/createOrder";
import "./inventory.scss";
import { InventoryRow } from "./inventoryRow";

export function InventoryPage() {
    const [error, setError] = useState<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [topLoader, setTopLoader] = useState(false);
    const [inventory, setInventory] = useState<inventoryNew[]>([]);
    const { state, setState } = useGlobalState();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selItem, setSelItem] = React.useState<inventoryNew | undefined>(undefined);

    const [tableRows, setTableRows] = useState(0);
    const [page, setPage] = useState(0);
    const [fetchedPages, setFetchedPages] = React.useState<number[]>([]);
    const [search, setSearch] = useState("");

    // useEffect(() => {
    //     async function getSuppliers() {
    //         try {
    //             let inventory = (
    //                 await axios.get<{ rows: number; data: inventoryNew[] }>(
    //                     "/api/inventory"
    //                 )
    //             ).data;
    //             inventory.data.sort((a, b) => {
    //                 if (a.sku < b.sku) return -1;
    //                 if (a.sku > b.sku) return 1;
    //                 return 0;
    //             });
    //             setTableRows(inventory.rows);
    //             setIsLoaded(true);
    //             setInventory(inventory.data);
    //         } catch (error) {
    //             setIsLoaded(true);
    //             setError(error);
    //             setState(
    //                 AddToToast(state, {
    //                     title: "Error",
    //                     description: "There was an error loading inventory",
    //                     type: toastType.Error,
    //                 })
    //             );
    //             console.error(error);
    //         }
    //     }

    //     getSuppliers();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        if (!state.ws) return;

        const wsMessage = (msg: MessageEvent<any>) => {
            try {
                let info = JSON.parse(msg.data);
                if (info.itemUpdate) updateInventory(info.itemUpdate);
                if (info.inventoryUpdate) updateInventory(info.inventoryUpdate);
            } catch (error) {}
        };

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventory]);

    function updateInventory(item: inventoryNew) {
        let itemIndex = inventory.findIndex(x => x.id === item.id);
        let newInv = [...inventory];
        if (itemIndex > -1) {
            newInv[itemIndex] = { ...newInv[itemIndex], ...item };
            newInv.sort((a, b) => {
                if (a.sku < b.sku) return -1;
                if (a.sku > b.sku) return 1;
                return 0;
            });
            newInv.sort((a, b) => {
                return a.stock - (a.q ?? 0) - (b.stock - (b.q ?? 0));
            });
            setInventory(newInv);
        }
    }

    async function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        if (!fetchedPages.includes(newPage)) {
            setIsLoaded(false);
            let orderReq = await axios.get<{
                rows: number;
                data: inventoryNew[];
            }>(`/api/inventory?page=${newPage}`);
            let newOrders = [...inventory, ...orderReq.data.data];
            setFetchedPages([...fetchedPages, newPage]);
            setInventory(newOrders);
            setTableRows(orderReq.data.rows);
            setIsLoaded(true);
        }
        setPage(newPage);
    }

    function openModal(item: inventoryNew) {
        setSelItem(item);
        setModalOpen(true);
    }

    function handleClose() {
        setSelItem(undefined);
        setModalOpen(false);
    }

    function filterFunction(searchVal: string) {
        setSearch(searchVal);
    }

    const headCells: { id: keyof inventoryNew; label: string; align: "left" | "right" | "inherit" | "center" | "justify" | undefined }[] = [
        { id: "sku", label: "SKU", align: "left" },
        { id: "description", label: "Description", align: "left" },
        { id: "stock", label: "Stock", align: "right" },
        { id: "qa", label: "Available", align: "right" },
        { id: "q", label: "Allocated/Required", align: "right" },
        { id: "onOrder", label: "On Order", align: "right" }
    ];

    const [orderBy, setOrderBy] = React.useState<string>("sku");
    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof inventoryNew) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property.toString());
        setTopLoader(true);
    };

    useEffect(() => {
        const timeoutId = setTimeout(async () => {
            try {
                let orders = (
                    await axios.get<{
                        rows: number;
                        data: inventoryNew[];
                    }>(`/api/inventory?search=${search}&sort=${orderBy}-${order.toUpperCase()}`)
                ).data;
                setIsLoaded(true);
                setTopLoader(false);
                setFetchedPages([0]);
                setPage(0);
                // orders.data.sort((a, b) => {
                //     if (a.sku < b.sku) return -1;
                //     if (a.sku > b.sku) return 1;
                //     return 0;
                // });
                setInventory(orders.data);
                setTableRows(orders.rows);
            } catch (error) {
                setIsLoaded(true);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading orders",
                        type: toastType.Error
                    })
                );
                console.error(error);
            }
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, orderBy, order]);

    if (!isLoaded) {
        return (
            <div className="card">
                <Loader />
            </div>
        );
    } else if (error) {
        return <div className="card">Error: {error?.message}</div>;
    } else {
        return (
            <div className="card">
                {topLoader ? (
                    <div className="topLoad">
                        <Loader onTop={true} />
                    </div>
                ) : (
                    ""
                )}
                <div className="itemHeader">
                    <h2>Inventory</h2>
                </div>
                <SearchBar filterFunction={filterFunction} />
                <br />
                <br />
                <TableContainer>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {headCells.map(headCell => (
                                    <TableCell key={headCell.id.toString()} sortDirection={orderBy === headCell.id ? order : false} align={headCell.align}>
                                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={(event: any) => handleRequestSort(event, headCell.id)}>
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                {/* <TableCell align="left">SKU</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell align="right">Stock</TableCell> */}
                                {/* <TableCell align="right">Available</TableCell>
                                <TableCell align="right">
                                    Allocated/Required
                                </TableCell> */}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>{inventory.map(row => <InventoryRow key={row.id} row={row} openModal={openModal} />).slice(page * 100, (page + 1) * 100)}</TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[100]}
                                    colSpan={7}
                                    count={tableRows ?? 0}
                                    rowsPerPage={100}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page"
                                        },
                                        native: true
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Dialog open={modalOpen} onClose={handleClose}>
                    <CreateOrder closeDialog={handleClose} item={selItem} />
                </Dialog>
            </div>
        );
    }
}
