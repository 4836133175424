import { IPurchaseOrder } from "../../../../Shared/Types/order";
import { useGlobalState } from "../../services/auth.service";
import { dateOnly } from "../../services/date.service";
import { sumProperty } from "../../services/generic.helper";

export function PurchaseOrderTemplate(props: { order: IPurchaseOrder }) {
    const { order } = props;
    const { state } = useGlobalState();
    return (
        <div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>Chocablock Chocolates</h4>
                <h4>Purchase Order</h4>
            </div>
            <p>Factory 15, 489a Warrigal Rd</p>
            <p>Moorabbin VIC 3189</p>
            <p>03 9039 5350</p>
            <hr />
            <p>P.O Number: [ {order.id.toString().padStart(4, "0")} ]</p>
            <p>
                P.O Date:{" "}
                {dateOnly(
                    order.sent_date ? new Date(order.sent_date) : new Date()
                )}
            </p>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <p>
                        <b>TO</b>
                    </p>
                    <p>{order.supplier.name}</p>
                    <p>{order.supplier.address}</p>
                    <p>
                        {order.supplier.city} {order.supplier.state}{" "}
                        {order.supplier.post_code}
                    </p>
                    <p>{order.supplier.phone}</p>
                </div>
                <div>
                    <p>
                        <b>SHIP TO</b>
                    </p>
                    <p>Chocablock Chocolates</p>
                    <p>Factory 15, 489a Warrigal Rd</p>
                    <p>Moorabbin VIC 3189</p>
                    <p>03 9039 5350</p>
                </div>
            </div>
            <hr />
            <table className="pTable" width="100%">
                <thead>
                    <tr>
                        <th>QTY</th>
                        <th>UNIT</th>
                        <th>Description</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {order.items.map((item) => (
                        <tr key={item.itemId}>
                            <td>{item.to_deliver / item.unit_quantity}</td>
                            <td>{item.sku} ({item.duof} of {item.unit_quantity} units)</td>
                            <td style={{ textAlign: "left" }}>
                                {item.description ?? "-"}
                            </td>
                            <td>${(item.cost / (item.to_deliver / item.unit_quantity)).toFixed(2)}</td>
                            <td>${item.cost.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td style={{ border: "none", textAlign: "right" }}></td>
                        <td style={{ border: "none", textAlign: "right" }}></td>
                        <td style={{ border: "none", textAlign: "right" }}></td>
                        <td style={{ border: "none", textAlign: "right" }}>
                            Subtotal:
                        </td>
                        <td>${sumProperty(order.items, "cost").toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td style={{ border: "none", textAlign: "right" }}></td>
                        <td style={{ border: "none", textAlign: "right" }}></td>
                        <td style={{ border: "none", textAlign: "right" }}></td>
                        <td style={{ border: "none", textAlign: "right" }}>
                            GST:
                        </td>
                        <td>${!!order.gst ? (sumProperty(order.items, "cost") * 0.1).toFixed(2) : "0.00"}</td>
                    </tr>
                    <tr>
                        <td style={{ border: "none", textAlign: "right" }}></td>
                        <td style={{ border: "none", textAlign: "right" }}></td>
                        <td style={{ border: "none", textAlign: "right" }}></td>
                        <td style={{ border: "none", textAlign: "right" }}>
                            Total:
                        </td>
                        <td>
                            $
                            {(
                                sumProperty(order.items, "cost") + (!!order.gst ? sumProperty(order.items, "cost") * 0.1 : 0)
                            ).toFixed(2)}
                        </td>
                    </tr>
                </tfoot>
            </table>
            <br />
            <p>Please send two copies of your invoice</p>
            <p>
                Enter this order in accordance with the prices, terms, delivery
                method, and specifications listed above.
            </p>
            <p>
                Please notify us immediately if you are unable to ship as
                specified.
            </p>
            <br />
            <p>
                Send all correspondence to: <br />
                Chocablock Chocolates <br />
                Factory 15, 489a Warrigal Rd, Moorabbin VIC 3189 <br />
                P:03 9039 5350 E: <a href="mailto:orders@chocablock.com.au">orders@chocablock.com.au</a> <br />
            </p>
            <p style={{ float: "right" }}>
                <b>Authorized by:</b> {state.user?.first_name}{" "}
                {state.user?.last_name} <b>Date:</b>{" "}
                {dateOnly(
                    order.sent_date ? new Date(order.sent_date) : new Date()
                )}
            </p>
        </div>
    );
}
