/* eslint-disable no-restricted-globals */
import { Tooltip } from "@mui/material";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Sidebar.scss";

export function Sidebar(props: { navOpen: boolean; theme: string; toggleTheme: Function }) {
    let curLocation = useLocation();

    const click = () => {
        props.toggleTheme();
        curTheme = curTheme === "dark" ? "light" : "dark";
        setThemeBtn();
    };

    let curTheme = props.theme;
    let themeButton = (
        <span onClick={click} className="fa material-icons">
            dark_mode
        </span>
    );

    function setThemeBtn() {
        if (curTheme === "dark") {
            themeButton = (
                <span onClick={click} className="fa material-icons">
                    light_mode
                </span>
            );
        } else {
            themeButton = (
                <span onClick={click} className="fa material-icons">
                    dark_mode
                </span>
            );
        }
    }

    setThemeBtn();
    return (
        <div className={`sidebar ${!props.navOpen ? "collapsed" : ""}`}>
            <nav className="sideNav">
                <ul className="accordion">
                    <NavLink end to="/" className={({ isActive }) => (isActive ? "navActive" : "")}>
                        <li>
                            <span className="infoHeader">
                                <i className="fa material-icons">home</i>
                                Home
                            </span>
                        </li>
                    </NavLink>
                    <NavLink end to="/dashboard" className={({ isActive }) => (isActive ? "navActive" : "")}>
                        <li>
                            <span className="infoHeader">
                                <i className="fa material-icons">dashboard</i>
                                Dashboard
                            </span>
                        </li>
                    </NavLink>
                    {/* <NavLink end to="/reports/item-recipes" className={({ isActive }) => isActive ? "navActive" : ""}> */}
                    <li className={"hasChildren" + (curLocation.pathname.includes("reports") ? " navActive" : "")}>
                        <span className="infoHeader">
                            <i className="fa material-icons">summarize</i>
                            Reports
                        </span>
                        <ul className="accordian">
                            <NavLink end to="/reports/item-recipes" className={({ isActive }) => (isActive ? "navActive" : "")}>
                                <li>
                                    <span className="infoHeader">Item Recipes</span>
                                </li>
                            </NavLink>
                        </ul>
                    </li>
                    {/* </NavLink> */}
                    <NavLink to="/inventory" className={({ isActive }) => (isActive ? "navActive" : "")}>
                        <li>
                            <span className="infoHeader">
                                <i className="fa material-icons">inventory_2</i>
                                Inventory
                            </span>
                        </li>
                    </NavLink>
                    <NavLink to="/orders" className={({ isActive }) => (isActive ? "navActive" : "")}>
                        <li>
                            <span className="infoHeader">
                                <i className="fa material-icons">request_page</i>
                                Purchase Orders
                            </span>
                        </li>
                    </NavLink>
                    <NavLink to="/recipes" className={({ isActive }) => (isActive ? "navActive" : "")}>
                        <li>
                            <span className="infoHeader">
                                <i className="fa material-icons">menu_book</i>
                                Recipes
                            </span>
                        </li>
                    </NavLink>
                    <NavLink to="/items" className={({ isActive }) => (isActive ? "navActive" : "")}>
                        <li>
                            <span className="infoHeader">
                                <i className="fa material-icons">category</i>
                                Items
                            </span>
                        </li>
                    </NavLink>
                    <NavLink to="/wastage" className={({ isActive }) => (isActive ? "navActive" : "")}>
                        <li>
                            <span className="infoHeader">
                                <i className="fa material-icons">delete</i>
                                Wastage
                            </span>
                        </li>
                    </NavLink>
                    <NavLink to="/charlie" className={({ isActive }) => (isActive ? "navActive" : "")}>
                        <li>
                            <span className="infoHeader">
                                <i className="fa material-icons">store</i>
                                Orders
                            </span>
                        </li>
                    </NavLink>
                    <NavLink to="/suppliers" className={({ isActive }) => (isActive ? "navActive" : "")}>
                        <li>
                            <span className="infoHeader">
                                <i className="fa material-icons">local_shipping</i>
                                Suppliers
                            </span>
                        </li>
                    </NavLink>
                </ul>
                <div className="navFooter">
                    <Tooltip title="Toggle light/dark theme">{themeButton}</Tooltip>
                </div>
            </nav>
        </div>
    );
}
