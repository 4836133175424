import { Box, Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getItemFlagsText, itemFlags } from "shared";
import { invDash } from "../../../../Shared/Types/inventory";
import { numFormat } from "../../services/generic.helper";
import "./dashboard.scss";

export function DashRow(props: { row: invDash }) {
    const row = props.row;
    const [open, setOpen] = useState(false);

    function getClass(val: number, danger: number, warn: number) {
        if (val <= danger) {
            return "negative";
        } else if (val <= warn) {
            return "warning";
        } else {
            return "positive";
        }
    }

    return (
        <React.Fragment>
            <TableRow className={row.flags & itemFlags.recount ? "recount" : ""}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <span className="material-icons">expand_less</span> : <span className="material-icons">expand_more</span>}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Link to={`/item/${row.id}`}>
                        {row.sku} - {row.description}
                    </Link>
                </TableCell>
                <TableCell align="right">
                    <span className={getClass(row.avaliable, 0, (row.qa ?? 0) - 1)}>{numFormat(row.avaliable)}</span>
                </TableCell>
                <TableCell align="right">{numFormat(row.onOrder)}</TableCell>
                <TableCell align="right">{numFormat(row.toOrder)}</TableCell>
                <TableCell align="right">{numFormat(row.qAPayment ?? 0)}</TableCell>
                <TableCell>
                    <div className="inlineItems">
                        <span>{getItemFlagsText(row.flags).length > 0 ? getItemFlagsText(row.flags).join(", ") : "-"}</span>
                        {row.flags & itemFlags.recount ? <span className="material-icons">published_with_changes</span> : ""}
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <h2 style={{ textAlign: "center" }}>Item Info</h2>
                            <h3 style={{ textAlign: "center" }}>Paid Orders</h3>
                            <div className="dashItemInfo">
                                <div title="Units Not Assigned To An Order">
                                    <span>Total Units Available: </span>
                                    <span className={getClass(row.avaliable, 0, (row.qa ?? 0) - 1)}>{numFormat(row.avaliable)}</span>
                                </div>
                                <div title="Units Currently On Order (Not Including Arrived Stock)">
                                    <span>Units Currently On Order: </span>
                                    <span>{numFormat(row.onOrder)}</span>
                                </div>
                                <div title="Units Required To Fulfill All Orders & Float">
                                    <span>Total Units Required: </span>
                                    <span>{numFormat(row.totalRequired)}</span>
                                </div>
                                <div title="Estimated Cost To Fulfill All Orders & Float">
                                    <span>Estimated Cost: </span>
                                    <span>
                                        ${numFormat(row.totalRequired * (row.cost ?? 0))} (at ${numFormat(row.cost ?? 0)} per unit)
                                    </span>
                                </div>
                            </div>
                            <div className="dashItemInfo">
                                <div title="Units Assigned To An Order">
                                    <span>Total Units Assigned To Orders: </span>
                                    <span className={getClass(row.qa ?? 0, (row.q ?? 0) * 0.5, (row.q ?? 0) - 1)}>{numFormat(row.qa ?? 0)}</span>
                                </div>
                                <div title="Units To Fulfill All Orders">
                                    <span>Total Units Required By Orders: </span>
                                    <span>{numFormat(row.q ?? 0)}</span>
                                </div>
                            </div>
                            <div className="dashItemInfo">
                                <div title="Current Stock On Hand">
                                    <span>Current Stock: </span>
                                    <span className={getClass(row.stock, 0, row.totalRequired)}>{numFormat(row.stock)}</span>
                                </div>
                                <div title="The Intended Amount Of Stock To Keep On Hand">
                                    <span>Item Float: </span>
                                    <span>{numFormat(row.float)}</span>
                                </div>
                                <div title="Count Of Orders Which Require This Item">
                                    <span>Orders Requiring Item: </span>
                                    <span>{numFormat(row.orderCount)}</span>
                                </div>
                            </div>
                            <br />
                            <hr />
                            <h3 style={{ textAlign: "center" }}>Awaiting Payment</h3>
                            <div className="dashItemInfo">
                                <div title="Units Required To Fulfill Orders That Are Awaiting Payment">
                                    <span>Units Required For Awaiting Payment: </span>
                                    <span>{numFormat(row.qAPayment ?? 0)}</span>
                                </div>
                                <div title="Units Assigned To Awaiting Payment Orders">
                                    <span>Units Assigned For Awaiting Payment: </span>
                                    <span>{numFormat(row.qaAPayment ?? 0)}</span>
                                </div>
                                <div title="Count Of Orders Which Require This Item & Are Also Awaiting Payment">
                                    <span>Orders Awaiting Payment: </span>
                                    <span>{numFormat(row.orderCountAPayment ?? 0)}</span>
                                </div>
                                <div title="Estimated Cost To Fulfill All Awaiting Payment Orders">
                                    <span>Estimated Cost: </span>
                                    <span>${row.qAPayment ? numFormat(row.qAPayment * (row.cost ?? 0)) + ` (at ${numFormat(row.cost ?? 0)} per unit)` : 0}</span>
                                </div>
                            </div>
                        </Box>
                        <br />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
