"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getItemFlags = exports.getItemFlagsText = exports.itemFlags = void 0;
var itemFlags;
(function (itemFlags) {
    itemFlags[itemFlags["recount"] = 1] = "recount";
})(itemFlags = exports.itemFlags || (exports.itemFlags = {}));
function getItemFlagsText(flags) {
    let text = [];
    for (const key in itemFlags) {
        if (Object.prototype.hasOwnProperty.call(itemFlags, key)) {
            if (flags & +itemFlags[key]) {
                text.push(key.charAt(0).toUpperCase() + key.slice(1));
            }
        }
    }
    return text;
}
exports.getItemFlagsText = getItemFlagsText;
function getItemFlags(flags) {
    let numbers = [];
    for (const key in itemFlags) {
        if (Object.prototype.hasOwnProperty.call(itemFlags, key)) {
            if (flags & +itemFlags[key]) {
                numbers.push(+itemFlags[key]);
            }
        }
    }
    return numbers;
}
exports.getItemFlags = getItemFlags;
