import { IconButton } from "@mui/material";
import { Editor, Element as SlateElement, Text, Transforms } from "slate";
import { jsx } from "slate-hyperscript";
import { ReactEditor, useSlate } from "slate-react";

export const deserialize = (el: HTMLElement | ChildNode): any => {
    if (el.nodeType === 3) {
        return el.textContent;
    } else if (el.nodeType !== 1) {
        return null;
    }

    let children = Array.from(el.childNodes).map(deserialize);

    if (children.length === 0) {
        children = [{ text: "" }];
    }

    switch (el.nodeName) {
        case "BODY":
            return jsx("fragment", {}, children);
        case "BR":
            return "\n";
        case "BLOCKQUOTE":
            return jsx("element", { type: "quote" }, children);
        case "P":
            return jsx("element", { type: "paragraph" }, children);
        case "A":
            return jsx("element", { type: "link", url: (el as HTMLElement).getAttribute("href") }, children);
        default:
            return el.textContent;
    }
};

export const serialize = (node: any) => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text);
        if ((node as any).bold) {
            string = `<strong>${string}</strong>`;
        }
        string = string.replaceAll("\n", "<br />");
        return string;
    }

    const children = node.children?.map((n: any) => serialize(n)).join("");

    switch (node.type) {
        case "quote":
            return `<blockquote><p>${children}</p></blockquote>`;
        case "paragraph":
            return `<p>${children}</p>`;
        case "link":
            return `<a href="${escapeHtml(node.url)}">${children}</a>`;
        default:
            return children;
    }
};

export const HOTKEYS: { [x: string]: string } = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code"
};

export const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const toggleBlock = (editor: ReactEditor, format: string) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type),
        split: true
    });
    const newProperties: Partial<SlateElement> & any = {
        type: isActive ? "paragraph" : isList ? "list-item" : format
    };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

export const isBlockActive = (editor: ReactEditor, format: string) => {
    const [match] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === format
    });

    return !!match;
};

export const toggleMark = (editor: ReactEditor, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

export const isMarkActive = (editor: ReactEditor, format: string) => {
    const marks: any = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const Element = ({ attributes, children, element }: { attributes: any; children: any; element: any }) => {
    switch (element.type) {
        case "block-quote":
            return <blockquote {...attributes}>{children}</blockquote>;
        case "bulleted-list":
            return <ul {...attributes}>{children}</ul>;
        case "heading-one":
            return <h1 {...attributes}>{children}</h1>;
        case "heading-two":
            return <h2 {...attributes}>{children}</h2>;
        case "list-item":
            return <li {...attributes}>{children}</li>;
        case "numbered-list":
            return <ol {...attributes}>{children}</ol>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

export const MarkButton = ({ format, icon }: { format: string; icon: string }) => {
    const editor = useSlate();
    return (
        <IconButton
            color={isMarkActive(editor, format) ? "primary" : "default"}
            onClick={event => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            <span className="material-icons">{icon}</span>
        </IconButton>
    );
};

export const BlockButton = ({ format, icon }: { format: string; icon: string }) => {
    const editor = useSlate();
    return (
        <IconButton
            color={isBlockActive(editor, format) ? "primary" : "default"}
            onClick={event => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            <span className="material-icons">{icon}</span>
        </IconButton>
    );
};

export const Leaf = ({ attributes, children, leaf }: { attributes: any; children: any; leaf: any }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};

const matchHtmlRegExp = /["'&<>]/;

function escapeHtml(string: string) {
    var str = "" + string;
    var match = matchHtmlRegExp.exec(str);

    if (!match) {
        return str;
    }

    var escape;
    var html = "";
    var index = 0;
    var lastIndex = 0;

    for (index = match.index; index < str.length; index++) {
        switch (str.charCodeAt(index)) {
            case 34: // "
                escape = "&quot;";
                break;
            case 38: // &
                escape = "&amp;";
                break;
            case 39: // '
                escape = "&#39;";
                break;
            case 60: // <
                escape = "&lt;";
                break;
            case 62: // >
                escape = "&gt;";
                break;
            default:
                continue;
        }

        if (lastIndex !== index) {
            html += str.substring(lastIndex, index);
        }

        lastIndex = index + 1;
        html += escape;
    }

    return lastIndex !== index ? html + str.substring(lastIndex, index) : html;
}
