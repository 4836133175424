import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { item } from "../../../../Shared/Types/items";
import { useGlobalState } from "../../services/auth.service";
import { dateStandard } from "../../services/date.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";

export function StockAdjustment(props: { item: item | null; closeDialog: Function }) {
    let { item, closeDialog } = props;

    let initValues = {
        ...{
            id: 0,
            sku: "",
            stock: 0,
            limit: null,
            float: 0,
            order_quantity: 0,
            loading: false,
            reason: ""
        },
        ...item
    };
    const [formState, setFormState] = useState(initValues);
    const { state, setState } = useGlobalState();

    async function handleClose(update: any) {
        if (!update) return closeDialog(false);
        setFormState({
            ...formState,
            loading: true
        });
        try {
            delete update.loading;
            if (update.limit === "") update.limit = null;
            let item = await axios.put(`/api/items/adjust/${formState.id}`, update);
            setState(
                AddToToast(state, {
                    title: "Success",
                    description: "Updated Item",
                    type: toastType.Success
                })
            );
            closeDialog(item.data);
        } catch (error) {
            console.error(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Couldn't Update Item",
                    type: toastType.Error
                })
            );
            closeDialog(false);
        }
    }

    const formValidation = Yup.object().shape({
        stock: Yup.number().required("Required"),
        reason: Yup.string().required("Required")
    });

    return (
        <React.Fragment>
            {formState.loading ? <Loader onTop={true} /> : ""}
            <DialogTitle>Adjust Item ({item?.sku})</DialogTitle>
            <Formik initialValues={initValues} onSubmit={(values: any) => handleClose(values)} validationSchema={formValidation}>
                {(props: any) => {
                    const { values, touched, errors, isSubmitting, handleChange, handleSubmit } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <DialogContentText>
                                    <small>Last modified {dateStandard(new Date(item ? item.modified : ""))}</small>
                                </DialogContentText>
                                <TextField margin="dense" name="stock" value={values.stock} onChange={handleChange} helperText={errors.stock && touched.stock && errors.stock} error={!!errors.stock} label="Stock on hand" type="number" fullWidth />
                                <TextField margin="dense" name="reason" value={values.reason} onChange={handleChange} helperText={errors.reason && touched.reason && errors.reason} error={!!errors.reason} label="Reason for adjustment" type="text" fullWidth />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button variant="contained" color="secondary" disabled={isSubmitting} type="submit">
                                    Update
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}
