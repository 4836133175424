/* eslint-disable no-restricted-globals */
import { Button, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { item } from "../../../../Shared/Types/items";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { SearchBar } from "../common/search/search";
import { toastType } from "../common/toast/toast";
import { EditItem } from "./editItem";
import "./item.scss";
import { ItemRow } from "./itemRow";
import { NewItem } from "./newItem";
import { StockAdjustment } from "./stockAdjustment";

export function ItemPage() {
    const [error, setError] = useState<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [search, setSearch] = useState("");
    const [items, setItems] = useState<item[]>([]);
    const [filteredItems, setFilteredItems] = useState<item[]>([]);
    const [selItem, setSelItem] = useState<item | null>(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [dialogType, setDialogType] = React.useState<"editItem" | "newItem" | "adjustItem" | null>(null);
    const { state, setState } = useGlobalState();
    // type recipeSort = Omit<item, "items"> & Required<"description">;

    useEffect(() => {
        async function getItems() {
            try {
                let items: item[] = (await axios.get<item[]>("/api/items/all")).data;
                items.sort((a, b) => {
                    if (a.stock < 0) return -1;
                    if (b.stock < 0) return 1;
                    if (a.sku < b.sku) return -1;
                    if (a.sku > b.sku) return 1;
                    return 0;
                });
                setIsLoaded(true);
                setItems(items);
                setFilteredItems(items);
            } catch (error) {
                setIsLoaded(true);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading items",
                        type: toastType.Error
                    })
                );
                console.error(error);
            }
        }

        getItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!state.ws) return;

        const wsMessage = (msg: MessageEvent<any>) => {
            try {
                let info = JSON.parse(msg.data);
                if (info.itemUpdate) updateItems(info.itemUpdate);
                if (info.itemHistory) updateHistory(info.itemHistory);
                if (info.itemDeleted) itemDelete(info.itemDeleted);
            } catch (error) {}
        };

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const itemDelete = (itemId: string) => {
        let newItems = [...items];
        newItems = newItems.filter(x => x.id !== parseInt(itemId));
        setItems(newItems);
        filterFunction(search, newItems);
    };

    const handleOpen = (item: item, type: "editItem" | "newItem" | "adjustItem" = "editItem") => {
        setDialogType(type);
        let { history, ...baseItem } = item;
        setSelItem(baseItem);
        setModalOpen(true);
    };

    const handleClose = (item?: item) => {
        if (item && !state.ws) {
            updateItems(item);
        }
        setModalOpen(false);
        setDialogType(null);
    };

    const updateItems = (item: item) => {
        let updatedIndex = items.findIndex(x => x.id === item.id);
        let newItems = [...items];
        if (updatedIndex > -1) {
            item.history = newItems[updatedIndex].history;
            newItems[updatedIndex] = item;
        } else {
            newItems.push(item);
        }
        newItems.sort((a, b) => {
            if (a.sku < b.sku) return -1;
            if (a.sku > b.sku) return 1;
            return 0;
        });
        setItems(newItems);
        filterFunction(search, newItems);
    };

    const updateHistory = (history: any) => {
        let updatedIndex = items.findIndex(x => x.id === history.id);
        let newItems = [...items];
        if (updatedIndex > -1) {
            newItems[updatedIndex].history = history.history;
            newItems[updatedIndex].history?.sort((a, b) => {
                return a.time.valueOf() - b.time.valueOf();
            });
        } else {
            return;
        }
        newItems.sort((a, b) => {
            if (a.sku < b.sku) return -1;
            if (a.sku > b.sku) return 1;
            return 0;
        });
        setItems(newItems);
    };

    const openNew = () => {
        setDialogType("newItem");
        setSelItem(null);
        setModalOpen(true);
    };

    function filterFunction(searchVal: string, newItems = items) {
        setSearch(searchVal);
        searchVal = searchVal.toLowerCase();
        if (searchVal === "") {
            setFilteredItems(newItems);
            return;
        }
        setFilteredItems(newItems.filter(x => x.id.toString().toLowerCase().includes(searchVal) || x.description?.toLowerCase().includes(searchVal) || x.sku.toLowerCase().includes(searchVal)));
    }

    const dialogContent = () => {
        let dialog;
        switch (dialogType) {
            case "newItem":
                dialog = <NewItem closeDialog={handleClose} />;
                break;
            case "editItem":
                dialog = <EditItem item={selItem} closeDialog={handleClose} />;
                break;
            case "adjustItem":
                dialog = <StockAdjustment item={selItem} closeDialog={handleClose} />;
                break;
            default:
                break;
        }
        return dialog;
    };

    // table sorting
    const headCells: { id: keyof item; label: string }[] = [
        { id: "sku", label: "SKU" },
        { id: "description", label: "Description" },
        { id: "stock", label: "Stock" }
        // { id: "limit", label: "Stock Limit (When it’s gone, it’s gone)" },
        // { id: "float", label: "Float" },
        // { id: "order_quantity", label: "Order Quantity" },
    ];

    const [orderBy, setOrderBy] = React.useState<string>("sku");
    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof item) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property.toString());
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator<Key extends keyof any>(order: Order, orderBy: string): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy as Key) : (a, b) => -descendingComparator(a, b, orderBy as Key);
    }

    function stableSort<T>(array: T[], comparator: (a: any, b: any) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    if (!isLoaded) {
        return (
            <div className="card">
                <Loader />
            </div>
        );
    } else if (error) {
        return <div className="card">Error: {error?.message}</div>;
    } else {
        return (
            <div className="card">
                <div className="itemHeader">
                    <h2>Items</h2>
                    <div>
                        <Button color="primary" onClick={openNew} startIcon={<span className="material-icons">add</span>}>
                            Add Item
                        </Button>
                    </div>
                </div>
                <SearchBar filterFunction={filterFunction} />
                <br />
                <br />
                <TableContainer>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                {headCells.map(headCell => (
                                    <TableCell key={headCell.id.toString()} sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={(event: any) => handleRequestSort(event, headCell.id)}>
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort<item>(
                                filteredItems.map(x => {
                                    let tmp: Partial<item> = { ...x };
                                    tmp.modified = tmp.modified?.toString();
                                    return tmp as item;
                                }),
                                getComparator(order, orderBy)
                            ).map(row => (
                                <ItemRow key={row.id} row={row} openModal={handleOpen} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {dialogType !== null ? <Dialog open={modalOpen}>{dialogContent()}</Dialog> : ""}
            </div>
        );
    }
}
