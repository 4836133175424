import { Button, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";

import { Formik } from "formik";
import * as Yup from "yup";
import { recipe, recipeQuery } from "../../../../Shared/Types/recipe";
import { supplier } from "../../../../Shared/Types/supplier";

import { useNavigate } from "react-router-dom";

export function CreateRecipe(props: { closeDialog: Function; duplicate?: recipe }) {
    let { closeDialog } = props;

    let history = useNavigate();

    let initValues: Partial<recipeQuery> & { loading: boolean } = {
        id: 0,
        sku: "",
        loading: false
    };
    if (props.duplicate) {
        initValues.description = props.duplicate.description;
        initValues.sku = props.duplicate.sku + " - Copy";
    }
    const [formState, setFormState] = useState<Partial<supplier> & { loading: boolean }>({ ...initValues });
    const { state, setState } = useGlobalState();

    async function handleClose(update: any) {
        if (!update) return closeDialog(false);
        setFormState({
            ...formState,
            loading: true
        });
        try {
            delete update.loading;
            if (!props.duplicate) {
                let item = await axios.post(`/api/recipe/new`, update);
                setState(
                    AddToToast(state, {
                        title: "Success",
                        description: "Created Recipe",
                        type: toastType.Success
                    })
                );
                history(`/recipe/${item.data.id}`);
                closeDialog(item.data);
            } else {
                let response = await axios.put<recipe>(`/api/recipe/duplicate`, { id: props.duplicate.id, sku: update.sku, desc: update.description });
                setState(
                    AddToToast(state, {
                        title: "Success",
                        description: "Recipe Duplicated",
                        type: toastType.Success
                    })
                );
                history(`/recipe/${await response.data.id}`);
                closeDialog();
            }
        } catch (error) {
            console.error(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Couldn't Create Recipe",
                    type: toastType.Error
                })
            );
            closeDialog(false);
        }
    }

    const formValidation = Yup.object().shape({
        sku: Yup.string().required("Required")
    });

    return (
        <React.Fragment>
            {formState.loading ? <Loader onTop={true} /> : ""}
            <DialogTitle>{props.duplicate ? "Duplicate" : "Create"} Recipe</DialogTitle>
            <Formik initialValues={initValues} onSubmit={(values: any) => handleClose(values)} validationSchema={formValidation}>
                {(props: any) => {
                    const { values, touched, errors, isSubmitting, handleChange, handleSubmit } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <TextField autoFocus margin="dense" name="sku" value={values.sku} onChange={handleChange} helperText={errors.sku && touched.sku && errors.sku} error={!!errors.sku && !!touched.sku} label="SKU" type="text" fullWidth />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    helperText={errors.description && touched.description && errors.description}
                                    error={!!errors.description && !!touched.description}
                                    label="Description"
                                    type="text"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button variant="contained" color="secondary" disabled={isSubmitting} type="submit">
                                    Create
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}
