/* eslint-disable no-restricted-globals */
import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { invDash } from "../../../../Shared/Types/inventory";
import { useGlobalState } from "../../services/auth.service";
import { dateOnly } from "../../services/date.service";
import { sqlDate } from "../../services/generic.helper";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { SearchBar } from "../common/search/search";
import { toastType } from "../common/toast/toast";
import "./dashboard.scss";
import { DashRow } from "./dashRow";

export function Dashboard() {
    const [error, setError] = useState<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [topLoader, setTopLoader] = useState(false);
    const [inventory, setInventory] = useState<invDash[]>([]);
    const { state, setState } = useGlobalState();

    const [tableRows, setTableRows] = useState(0);
    const [page, setPage] = useState(0);
    const [fetchedPages, setFetchedPages] = React.useState<number[]>([]);
    const [search, setSearch] = useState("");
    const [notAllocated, setNotAllocated] = useState<boolean>(false);
    let date = new Date();
    date.setDate(date.getDate() + 14);
    const [searchDate, setSearchDate] = useState<Date>(date);

    const firstUpdate = useRef(true);

    async function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        if (!fetchedPages.includes(newPage)) {
            setIsLoaded(false);
            let orderReq = await axios.get<{
                rows: number;
                data: invDash[];
            }>(`/api/inventory/dash?search=${search}&sort=${orderBy}-${order.toUpperCase()}&notAllocated=${notAllocated}&date=${sqlDate(searchDate)}&page=${newPage}`);
            let newOrders = [...inventory, ...orderReq.data.data];
            setFetchedPages([...fetchedPages, newPage]);
            setInventory(newOrders);
            setTableRows(orderReq.data.rows);
            setIsLoaded(true);
        }
        setPage(newPage);
    }

    function filterFunction(searchVal: string) {
        setSearch(searchVal);
    }

    const headCells: { id: keyof invDash; label: string; align: "left" | "right" | "inherit" | "center" | "justify" | undefined }[] = [
        { id: "sku", label: "SKU", align: "left" },
        { id: "qa", label: "Available", align: "right" },
        { id: "onOrder", label: "On Order", align: "right" },
        { id: "toOrder", label: "Need To Order", align: "right" },
        { id: "qaAPayment", label: "Awaiting Payment", align: "right" },
        { id: "flags", label: "Flags", align: "left" }
    ];

    const [orderBy, setOrderBy] = React.useState<string>("toOrder");
    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("desc");

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof invDash) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property.toString());
        setTopLoader(true);
    };

    async function updateData() {
        setTopLoader(true);
        try {
            let orders = (
                await axios.get<{
                    rows: number;
                    data: invDash[];
                }>(`/api/inventory/dash?search=${search}&sort=${orderBy}-${order.toUpperCase()}&notAllocated=${notAllocated}&date=${sqlDate(searchDate)}`)
            ).data;
            setIsLoaded(true);
            setTopLoader(false);
            setFetchedPages([0]);
            setPage(0);
            setInventory(orders.data);
            setTableRows(orders.rows);
        } catch (error) {
            setIsLoaded(true);
            setError(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "There was an error loading orders",
                    type: toastType.Error
                })
            );
            console.error(error);
        }
    }

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const timeoutId = setTimeout(async () => {
            updateData();
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderBy, order, notAllocated, searchDate]);

    if (!isLoaded) {
        return (
            <div className="card">
                <Loader />
            </div>
        );
    } else if (error) {
        return <div className="card">Error: {error?.message}</div>;
    } else {
        return (
            <div className="card">
                {topLoader ? (
                    <div className="topLoad">
                        <Loader onTop={true} />
                    </div>
                ) : (
                    ""
                )}
                <div className="itemHeader">
                    <h2>Inventory Dashboard</h2>
                </div>
                <SearchBar filterFunction={filterFunction} />
                <br />
                <br />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <FormControlLabel control={<Checkbox name="notAllocated" onChange={() => setNotAllocated(!notAllocated)} />} label="Show only unfulfilled items" />
                    <DatePicker
                        label="Orders Until"
                        value={searchDate}
                        onChange={(newValue: Date | null) => {
                            setSearchDate(newValue ?? new Date());
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                        inputFormat="DD/MM/YYYY"
                    />
                </div>
                <br />
                <p>Showing data for orders that have a dispatch date before {dateOnly(searchDate)}</p>
                <br />
                <TableContainer>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {headCells.map(headCell => (
                                    <TableCell key={headCell.id.toString()} sortDirection={orderBy === headCell.id ? order : false} align={headCell.align}>
                                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={(event: any) => handleRequestSort(event, headCell.id)}>
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {inventory.map(row => (
                                <DashRow key={row.id} row={row} />
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[100]}
                                    colSpan={7}
                                    count={tableRows ?? 0}
                                    rowsPerPage={100}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page"
                                        },
                                        native: true
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}
