import { IconButton } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Reports } from "../../../../../Shared/Types/report";

export function Item(props: { item: Reports.IItem }) {
    const item = props.item;

    let [open, setOpen] = useState(false);

    return (
        <div className={"listItem" + (open ? " open" : "")} key={item.id}>
            <Link to={`/item/${item.id}`}>
                <h4>{item.sku}</h4>
            </Link>
            <p>{item.description}</p>
            <p>Inlcuded in {item.recipes.length} recipe(s)</p>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <span className="material-icons">expand_less</span> : <span className="material-icons">expand_more</span>}
            </IconButton>
            <div className="recipes">
                <h4>Recipes:</h4>
                {!open ? (
                    ""
                ) : (
                    <ul className="dashed">
                        {item.recipes.map(r => (
                            <li key={r.id}>
                                <Link to={`/recipe/${r.id}`}>{r.sku}</Link> - {r.description}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}
