/* eslint-disable no-restricted-globals */
import { Button, Chip, Dialog, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { purchaseOrder } from "../../../../Shared/Types/order";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";
import { CreateOrder } from "./createOrder";
import { EditOrder } from "./editOrder";
import { OrderRow } from "./orderRow";
import "./orders.scss";

export function PurchaseOrderPage() {
    const [error, setError] = useState<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [orders, setOrders] = useState<purchaseOrder[]>([]);
    const [orderRows, setOrderRows] = useState(0);
    const [page, setPage] = useState(0);
    const [fetchedPages, setFetchedPages] = React.useState<number[]>([]);
    const [selOrder, setSelOrder] = useState<purchaseOrder | null>(null);
    const { state, setState } = useGlobalState();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [dialogType, setDialogType] = React.useState<"newOrder" | "editOrder" | null>(null);
    const [orderType, setOrderType] = React.useState<"unfulfilled" | "fulfilled">("unfulfilled");

    useEffect(() => {
        async function getPurchaseOrders() {
            try {
                let tmpOrders: { rows: number; data: purchaseOrder[] } = (await axios.get<{ rows: number; data: purchaseOrder[] }>("/api/orders")).data;
                setIsLoaded(true);
                setOrders(tmpOrders.data);
                setOrderRows(tmpOrders.rows);
            } catch (error) {
                setIsLoaded(true);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading inventory",
                        type: toastType.Error
                    })
                );
                console.error(error);
            }
        }

        getPurchaseOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        if (!fetchedPages.includes(newPage)) {
            setIsLoaded(false);
            let orderReq = await axios.get<{
                rows: number;
                data: purchaseOrder[];
            }>(`/api/orders${orderType === "fulfilled" ? "/fulfilled" : ""}?page=${newPage}`);
            let newOrders = [...orders, ...orderReq.data.data];
            setFetchedPages([...fetchedPages, newPage]);
            setOrders(newOrders);
            setOrderRows(orderReq.data.rows);
            setIsLoaded(true);
        }
        setPage(newPage);
    }

    useEffect(() => {
        if (!state.ws) return;

        const wsMessage = (msg: MessageEvent<any>) => {
            try {
                let info = JSON.parse(msg.data);
                if (info.purchaseOrderBasic) updateOrders(info.purchaseOrderBasic);
                if (info.orderDeleted) deleteOrder(info.orderDeleted);
            } catch (error) {}
        };

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders]);

    function deleteOrder(orderId: any) {
        let newOrders = [...orders];
        newOrders = newOrders.filter(x => x.id !== parseInt(orderId));
        setOrders(newOrders);
    }

    function updateOrders(order: purchaseOrder) {
        let orderIndex = orders.findIndex(x => x.id === order.id);
        let newOrders = [...orders];
        if (orderIndex > -1) {
            newOrders[orderIndex] = order;
        } else {
            newOrders.push(order);
        }
        setOrders(newOrders);
    }

    useEffect(() => {
        handleChangePage(null, page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderType]);

    function updateOrderType(newType: "fulfilled" | "unfulfilled") {
        setOrders([]);
        setFetchedPages([]);
        setPage(0);
        setOrderType(newType);
    }

    function openNew() {
        setDialogType("newOrder");
        setModalOpen(true);
    }

    function openEdit(order: purchaseOrder) {
        setDialogType("editOrder");
        setSelOrder(order);
        setModalOpen(true);
    }

    function handleClose() {
        setModalOpen(false);
    }

    const dialogContent = () => {
        let dialog;
        switch (dialogType) {
            case "newOrder":
                dialog = <CreateOrder closeDialog={handleClose} />;
                break;
            case "editOrder":
                if (selOrder) dialog = <EditOrder closeDialog={handleClose} order={selOrder} />;
                break;
            default:
                break;
        }
        return dialog;
    };

    if (!isLoaded) {
        return (
            <div className="card">
                <Loader />
            </div>
        );
    } else if (error) {
        return <div className="card">Error: {error?.message}</div>;
    } else {
        return (
            <div className="card">
                <div className="itemHeader">
                    <h2>Purchase Orders</h2>
                    <div>
                        <Button color="primary" onClick={openNew} startIcon={<span className="material-icons">add</span>}>
                            Purchase Order
                        </Button>
                    </div>
                </div>
                <div className="chips">
                    <Chip variant="outlined" size="small" label="Unfulfilled" color={orderType === "unfulfilled" ? "primary" : "default"} disabled={orderType === "unfulfilled"} onClick={() => updateOrderType("unfulfilled")} />
                    <Chip variant="outlined" size="small" label="Fulfilled" color={orderType === "fulfilled" ? "primary" : "default"} disabled={orderType === "fulfilled"} onClick={() => updateOrderType("fulfilled")} />
                </div>
                <TableContainer>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="left">Supplier</TableCell>
                                <TableCell align="right">Item</TableCell>
                                <TableCell align="right">Cost</TableCell>
                                <TableCell align="right">Date Ordered</TableCell>
                                <TableCell align="right">Expected Date</TableCell>
                                <TableCell align="right">Status</TableCell>
                                {orderType !== "fulfilled" && (
                                    <React.Fragment>
                                        <TableCell align="right">Approved</TableCell>
                                        <TableCell align="right">Ordered</TableCell>
                                    </React.Fragment>
                                )}
                                {orderType === "fulfilled" && <TableCell align="right">Fulfilled</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>{orders.map(row => <OrderRow openModal={openEdit} key={row.id} row={row} orderType={orderType} />).slice(page * 50, (page + 1) * 50)}</TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[50]}
                                    colSpan={9}
                                    count={orderRows ?? 0}
                                    rowsPerPage={50}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page"
                                        },
                                        native: true
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="md">
                    {dialogContent()}
                </Dialog>
            </div>
        );
    }
}
