import { Checkbox, TableCell, TableRow } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { purchaseOrder } from "../../../../Shared/Types/order";
import { dateStandard } from "../../services/date.service";
import { lerp } from "../../services/generic.helper";
import "./orders.scss";

export function OrderRow(props: { row: purchaseOrder; openModal: Function; orderType: "unfulfilled" | "fulfilled" }) {
    const { row } = props;

    const isLate = row.expected_date ? new Date(row.expected_date).getTime() < new Date().getTime() : false;

    return (
        <React.Fragment>
            <TableRow className={isLate ? "late" : ""}>
                <TableCell>
                    <Link
                        to={{
                            pathname: `/orders/${row.id}`
                        }}
                    >
                        #{row.id}
                    </Link>
                </TableCell>
                <TableCell>{row.supplierName}</TableCell>
                <TableCell align="right">{row.skus}</TableCell>
                <TableCell align="right">${row.cost.toFixed(2)}</TableCell>
                <TableCell align="right">{dateStandard(new Date(row.created))}</TableCell>
                <TableCell align="right">{row.expected_date ? dateStandard(new Date(row.expected_date)) : "-"}</TableCell>
                <TableCell align="right">
                    <div className="progressBox">
                        <div className="boxText">
                            {row.delivered}/{row.to_deliver}
                        </div>
                        <div
                            className="boxBack"
                            style={{
                                width: (row.delivered / row.to_deliver) * 100 + "%",
                                backgroundColor: `hsl(${lerp(0, 120, row.delivered / row.to_deliver)}, 100%, 50%)`
                            }}
                        ></div>
                    </div>
                </TableCell>
                {props.orderType !== "fulfilled" && (
                    <React.Fragment>
                        <TableCell align="right">
                            <Checkbox checked={!!row.approved} disabled />
                        </TableCell>
                        <TableCell align="right">
                            <Checkbox checked={!!row.ordered} disabled />
                        </TableCell>
                    </React.Fragment>
                )}
                {props.orderType === "fulfilled" && <TableCell align="right">{dateStandard(new Date(row.fulfilled ?? "-"))}</TableCell>}
            </TableRow>
        </React.Fragment>
    );
}
