import { ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import "./App.scss";
import { Router } from "./components/router/router";
import { authCon, GlobalStateProvider } from "./services/auth.service";

function App() {
    const [theme, setThemeActive] = useState(localStorage.getItem("theme") ?? "light");

    (async () => {
        await authCon.getUser();
    })();

    const matTheme = createMuiTheme({
        palette: {
            primary: {
                light: "#91f2ff",
                main: "#5bbfde",
                dark: "#158fac",
                contrastText: "#000000"
            },
            secondary: {
                light: "#ff66e5",
                main: "#d529b3",
                dark: "#a00083",
                contrastText: "#fff"
            },
            mode: theme === "dark" ? "dark" : "light"
        },
        components: {
            MuiTextField: {
                defaultProps: {
                    variant: "standard"
                }
            }
        }
    });

    function setTheme() {
        let curTheme = localStorage.getItem("theme");
        if (!curTheme) {
            curTheme = "light";
            localStorage.setItem("theme", "light");
        }
        document.body.classList.add(theme);
    }

    setTheme();

    return (
        <ThemeProvider theme={matTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <GlobalStateProvider>
                    <Router theme={theme} setThemeActive={setThemeActive} />
                </GlobalStateProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
