import { Button, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";

import { Formik } from "formik";
import * as Yup from "yup";

export function NewItem(props: { closeDialog: Function }) {
    let { closeDialog } = props;

    let initValues = {
        id: 0,
        sku: "",
        stock: 0,
        description: "",
        limit: null,
        float: 0,
        order_quantity: 0,
        loading: false,
        duof: "Box",
        unit_quantity: 12
    };
    const [formState, setFormState] = useState({ ...initValues });
    const { state, setState } = useGlobalState();

    async function handleClose(update: any) {
        if (!update) return closeDialog(false);
        setFormState({
            ...formState,
            loading: true
        });
        try {
            delete update.loading;
            if (update.limit === "") update.limit = null;
            let item = await axios.post(`/api/items/new`, update);
            setState(
                AddToToast(state, {
                    title: "Success",
                    description: "Created Item",
                    type: toastType.Success
                })
            );
            closeDialog(item.data);
        } catch (error) {
            console.error(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Couldn't Create Items",
                    type: toastType.Error
                })
            );
            closeDialog(false);
        }
    }

    const formValidation = Yup.object().shape({
        sku: Yup.string().required("Required"),
        stock: Yup.number().required("Required"),
        float: Yup.number().required("Required")
    });

    return (
        <React.Fragment>
            {formState.loading ? <Loader onTop={true} /> : ""}
            <DialogTitle>Create Item</DialogTitle>
            <Formik initialValues={initValues} onSubmit={(values: any) => handleClose(values)} validationSchema={formValidation}>
                {(props: any) => {
                    const { values, touched, errors, isSubmitting, handleChange, handleSubmit } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <TextField autoFocus margin="dense" name="sku" value={values.sku} onChange={handleChange} helperText={errors.sku && touched.sku && errors.sku} error={!!errors.sku} label="SKU" type="text" fullWidth />
                                <TextField margin="dense" name="description" value={values.description} onChange={handleChange} helperText={errors.description && touched.description && errors.description} error={!!errors.description} label="Description" type="text" fullWidth />
                                <TextField margin="dense" name="stock" value={values.stock} onChange={handleChange} helperText={errors.stock && touched.stock && errors.stock} error={!!errors.stock} label="Stock on hand" type="number" fullWidth />
                                <TextField margin="dense" name="limit" value={values.limit ?? ""} onChange={handleChange} label="Stock Limit" helperText="Leave blank to remove limit" type="number" fullWidth />
                                <TextField margin="dense" name="float" value={values.float} onChange={handleChange} label="Stock Float" helperText="The amount of stock to keep on hand, setting to 0 will disable automatic orders" type="number" fullWidth />
                                <TextField
                                    margin="dense"
                                    name="order_quantity"
                                    value={values.order_quantity}
                                    onChange={handleChange}
                                    helperText={errors.order_quantity && touched.order_quantity && errors.order_quantity}
                                    error={!!errors.order_quantity}
                                    label="Order Quantity"
                                    type="number"
                                    fullWidth
                                />
                                <TextField margin="dense" name="duof" value={values.duof} onChange={handleChange} label="Unit of Measurement" helperText={errors.duof && touched.duof && errors.duof} type="text" fullWidth />
                                <TextField margin="dense" name="unit_quantity" value={values.unit_quantity} onChange={handleChange} label="Unit Quantity" helperText={errors.unit_quantity && touched.unit_quantity && errors.unit_quantity} type="number" fullWidth />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button variant="contained" color="secondary" disabled={isSubmitting} type="submit">
                                    Create
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}
