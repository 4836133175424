import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./Page404.scss";

export class Page404 extends React.Component<{}> {
    render() {
        return (
            <div className="pageCover">
                <h2>Page Not Found</h2>
                <Link to="/">
                    <Button variant="outlined" color="primary">
                        Return Home
                    </Button>
                </Link>
            </div>
        );
    }
}
