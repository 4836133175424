/* eslint-disable no-restricted-globals */
import { Button, Checkbox, Chip, Dialog, FormControlLabel, Paper, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import "chart.js/auto";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getItemFlagsText, itemFlags } from "shared";
import * as Yup from "yup";
import { item } from "../../../../Shared/Types/items";
import { useGlobalState } from "../../services/auth.service";
import { dateOnly, dateStandard } from "../../services/date.service";
import { AddToToast } from "../../services/toast.service";
import { Confirmation } from "../common/confirmationDialog/confirmation";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";
import { HistoryTable } from "../shared/history/history";
import { StockAdjustment } from "./stockAdjustment";

import React from "react";
import { Line } from "react-chartjs-2";

export function ItemDetail() {
    let { itemId } = useParams<{ itemId: string }>();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(false);
    const { state, setState } = useGlobalState();
    const [item, setItem] = useState<item | null>(null);
    const [openDuplicate, setOpenDuplicate] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    let RouterHistory = useNavigate();

    useEffect(() => {
        async function getItem() {
            setLoading(true);
            try {
                let tmpOrder: item = (await axios.get<item>("/api/item/" + itemId)).data;
                setLoading(false);
                setItem(tmpOrder);
            } catch (error) {
                setLoading(false);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading the item",
                        type: toastType.Error
                    })
                );
                console.error(error);
            }
        }

        getItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!state.ws) return;

        const wsMessage = (msg: MessageEvent<any>) => {
            try {
                let info = JSON.parse(msg.data);
                if (info.itemUpdate) itemUpdated(info.itemUpdate);
                if (info.itemDeleted) itemDeleted(info.itemDeleted);
            } catch (error) {}
        };

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    function itemDeleted(deletedItem: string) {
        if (parseInt(deletedItem) === item?.id) {
            RouterHistory("/items");
            setState(
                AddToToast(state, {
                    title: "Warning",
                    description: "Item delted",
                    type: toastType.Warning
                })
            );
        }
    }

    function itemUpdated(newItem: item) {
        if (newItem.id === item?.id) {
            if (!newItem.stats) newItem.stats = item.stats;
            setItem(newItem);
        }
    }

    const [tab, setTab] = useState(0);

    const hangleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    async function updateItem(update: item) {
        update = JSON.parse(JSON.stringify(update));
        if (Array.isArray(update.archived)) update.archived = update.archived[0] === "on" ? true : false;
        try {
            if (!update.limit) update.limit = undefined;
            update.suppliers = undefined;
            update.recipes = undefined;
            await axios.put(`/api/items/${item?.id}`, update);
            setState(
                AddToToast(state, {
                    title: "Success",
                    description: "Updated Item",
                    type: toastType.Success
                })
            );
            setModalOpen(false);
        } catch (error) {
            console.error(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Couldn't Update Item",
                    type: toastType.Error
                })
            );
        }
    }

    const deleteInfo = {
        header: "Delete Item",
        text: "Are you sure you want to delete this item, it cannot be undone",
        confirmText: "Delete",
        cancelText: "Cancel",
        closeFunction: deleteItem
    };

    const duplicateInfo = {
        header: "Duplicate Item",
        text: "Are you sure you want to duplicate this item.",
        confirmText: "Duplicate",
        cancelText: "Cancel",
        closeFunction: duplicateItem
    };

    async function deleteItem(canDelete = false) {
        setOpenDelete(false);
        if (canDelete && item) {
            try {
                await axios.delete(`/api/item/${item.id}`);
                setState(
                    AddToToast(state, {
                        title: "Success",
                        description: "Item Deleted",
                        type: toastType.Success
                    })
                );
            } catch (error) {
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "Item wasn't Deleted",
                        type: toastType.Error
                    })
                );
            }
        }
    }

    async function duplicateItem(canDuplicate = false) {
        setOpenDuplicate(false);
        if (canDuplicate && item) {
            try {
                let r = await axios.post<item>(`/api/item/${item.id}/duplicate`);
                setTimeout(() => {
                    RouterHistory(`/item/${r.data.id}`, {
                        replace: true
                    });
                }, 300);
                RouterHistory("/items");
                setState(
                    AddToToast(state, {
                        title: "Success",
                        description: "Item Duplicated",
                        type: toastType.Success
                    })
                );
            } catch (error) {
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "Item wasn't duplicated",
                        type: toastType.Error
                    })
                );
            }
        }
    }

    const formValidation = Yup.object().shape({
        sku: Yup.string().required("Required"),
        stock: Yup.number().required("Required"),
        float: Yup.number().required("Required"),
        description: Yup.string().nullable(),
        limit: Yup.number().nullable()
    });

    // History stuff
    const [historyType, setHistoryType] = useState<"history" | "adjustments">("history");
    const updateHistoryType = (type: "history" | "adjustments") => {
        setHistoryType(type);
        if (historyRef.current) {
            historyRef.current.updateType(type);
        }
    };
    const historyRef = useRef<any>();

    //chart stuff
    let data1: any = {};
    let data2: any = {};
    if (item?.stats) {
        data1 = {
            labels: item.stats.history.map(x => dateOnly(new Date(x.date))),
            datasets: [
                {
                    label: "Stock History",
                    data: item.stats.history.map(x => +x.value),
                    fill: false,
                    backgroundColor: "#d529b2",
                    borderColor: "#a7009a"
                }
            ]
        };
        data2 = {
            labels: item.stats.deliveries.map(x => dateOnly(new Date(x.date))),
            datasets: [
                {
                    label: "Delivery History",
                    data: item.stats.deliveries.map(x => +x.value),
                    fill: false,
                    backgroundColor: "#4b9ab0",
                    borderColor: "#6fd3ed"
                }
            ]
        };
    }

    const options: any = {
        responsive: true,
        hover: {
            mode: "nearest",
            intersect: true
        },
        scales: {
            y: {
                ticks: {
                    stepSize: 1
                }
            }
        },
        plugins: {
            tooltip: {
                mode: "index",
                intersect: false
            }
        }
    };

    if (error) return <div className="card">Error: {error?.message}</div>;
    if (loading || !item)
        return (
            <div className="card">
                <Loader />
            </div>
        );
    return (
        <div className="card">
            <div className="itemHeader">
                <h2>
                    {item.sku} - {item.description}
                </h2>
            </div>
            Last modified &nbsp;
            {dateStandard(new Date(item ? item.modified : ""))}
            <br />
            <br />
            <Paper>
                <Tabs centered value={tab} onChange={hangleTabChange}>
                    <Tab label="Info" />
                    <Tab label="Edit" />
                    <Tab label="History" />
                </Tabs>
            </Paper>
            <div hidden={tab !== 0}>
                <p style={{ padding: "10px 20px" }}>
                    <b>Current Stock: </b>
                    <span className={`${item.stock > item.float ? "positive" : item.stock > 0 ? "warning" : "negative"}`}>{item.stock}</span>
                    <br />
                    <b>Float: </b> {item.float}
                    <br />
                    <b>Average Stock Level: </b> {((item.stats?.history.reduce((a, b) => a + (+b.value || 0), 0) ?? 1) / (item.stats?.history.length ?? 1)).toFixed(0)}
                    <br />
                    <b>Average Cost per unit: </b> ${item.stats?.avgCost.toFixed(2)}
                    <br />
                    <b>Suppliers: </b> {item.suppliers?.join(", ")}
                    <br />
                    <b>Flags: </b> {getItemFlagsText(item.flags).length > 0 ? getItemFlagsText(item.flags).join(", ") : "None"}
                </p>
                <div style={{ padding: "10px 20px" }}>
                    {(item.recipes?.length ?? 0) === 0 ? (
                        <b>Not in any recipes</b>
                    ) : (
                        <React.Fragment>
                            <b>Recipes: </b>
                            <ul>
                                {item.recipes?.map(x => (
                                    <li key={x.id}>
                                        <Link to={`/recipe/${x.id}`}>
                                            {x.sku} - {x.description}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </React.Fragment>
                    )}
                </div>
                <div style={{ display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
                    <div style={{ flexBasis: "45%" }}>
                        <Line data={data1} options={options} />
                    </div>
                    <div style={{ flexBasis: "45%" }}>
                        <Line data={data2} options={options} />
                    </div>
                </div>
            </div>
            <div hidden={tab !== 1}>
                <Formik initialValues={item} onSubmit={(values: any) => updateItem(values)} validationSchema={formValidation} enableReinitialize={true}>
                    {(props: { values: item; [key: string]: any }) => {
                        const { values, touched, errors, isSubmitting, handleChange, handleSubmit, setFieldValue } = props;

                        return (
                            <form onSubmit={handleSubmit} className="itemForm">
                                <TextField autoFocus margin="dense" name="sku" value={values.sku} onChange={handleChange} helperText={errors.sku && touched.sku && errors.sku} label="SKU" type="text" fullWidth />
                                <TextField margin="dense" name="description" value={values.description ?? ""} onChange={handleChange} helperText={errors.description && touched.description && errors.description} label="Description" type="text" fullWidth />
                                <TextField margin="dense" name="limit" value={values.limit ?? ""} onChange={handleChange} label="Stock Limit (When it’s gone, it’s gone)" helperText="Leave blank to remove limit" type="number" fullWidth />
                                <TextField margin="dense" name="float" value={values.float} onChange={handleChange} label="Stock Float" helperText="The amount of stock to keep on hand, setting to 0 will disable automatic orders" type="number" fullWidth />
                                <TextField margin="dense" name="order_quantity" value={values.order_quantity} onChange={handleChange} label="Order Quantity" helperText={errors.order_quantity && touched.order_quantity && errors.order_quantity} type="number" fullWidth />
                                <TextField margin="dense" name="duof" value={values.duof} onChange={handleChange} label="Unit of Measurement" helperText={errors.duof && touched.duof && errors.duof} type="text" fullWidth />
                                <TextField margin="dense" name="unit_quantity" value={values.unit_quantity} onChange={handleChange} label="Unit Quantity" helperText={errors.unit_quantity && touched.unit_quantity && errors.unit_quantity} type="number" fullWidth />
                                <FormControlLabel control={<Checkbox name="archived" checked={!!values.archived} onChange={handleChange} />} label="Item Archived" />
                                <br />
                                <div>
                                    <p>Item Flags:</p>
                                    {Object.keys(itemFlags).map(x => {
                                        if (isNaN(+x)) return null;
                                        return (
                                            <div key={x}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={!!(values.flags && +x)}
                                                            onChange={v => {
                                                                if (v.target.checked) {
                                                                    setFieldValue("flags", (values.flags |= +x));
                                                                } else {
                                                                    setFieldValue("flags", (values.flags &= ~+x));
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={itemFlags[+x].charAt(0).toUpperCase() + itemFlags[+x].slice(1)}
                                                />
                                                <br />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div style={{ flexBasis: "100%" }}>
                                    <Button variant="contained" color="secondary" disabled={isSubmitting || Object.keys(errors).length > 0} type="submit">
                                        Update
                                    </Button>
                                </div>
                                {/* <pre>
                                    {JSON.stringify(values, null, 2)}
                                </pre> */}
                            </form>
                        );
                    }}
                </Formik>
                <Tooltip title="Adjust">
                    <Button
                        color="secondary"
                        onClick={() => {
                            setModalOpen(true);
                        }}
                    >
                        <span className="material-icons">tune</span>
                        Adjust Stock
                    </Button>
                </Tooltip>
                <Tooltip title="Duplicate">
                    <Button
                        className="secondary"
                        onClick={() => {
                            setOpenDuplicate(true);
                        }}
                    >
                        <span className="material-icons">content_copy</span>
                        Duplicate
                    </Button>
                </Tooltip>
                <Tooltip title="Delete">
                    <Button
                        className="dangerBtn"
                        onClick={() => {
                            setOpenDelete(true);
                        }}
                    >
                        <span className="material-icons">delete</span>
                        Delete
                    </Button>
                </Tooltip>
            </div>
            <div hidden={tab !== 2}>
                <h3>History</h3>
                <div className="chips">
                    <Chip variant="outlined" size="small" label="All" color={historyType === "history" ? "primary" : "default"} onClick={() => updateHistoryType("history")} />
                    <Chip variant="outlined" size="small" label="Adjustments" color={historyType === "adjustments" ? "primary" : "default"} onClick={() => updateHistoryType("adjustments")} />
                </div>
                <HistoryTable id={item.id} location="items" ref={historyRef} eventName="itemHistory" />
            </div>
            <Dialog open={modalOpen}>
                <StockAdjustment
                    item={item}
                    closeDialog={() => {
                        setModalOpen(false);
                    }}
                />
            </Dialog>
            <Confirmation info={deleteInfo} isOpen={openDelete} />
            <Confirmation info={duplicateInfo} isOpen={openDuplicate} />
        </div>
    );
}
