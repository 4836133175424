import Autocomplete from "@mui/lab/Autocomplete";
import { Button, Chip, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";

import { Formik } from "formik";
import * as Yup from "yup";
import { item } from "../../../../Shared/Types/items";
import { supplier } from "../../../../Shared/Types/supplier";

export function EditSupplier(props: { supplier: supplier | null; closeDialog: Function }) {
    let { supplier, closeDialog } = props;

    let initValues: Partial<supplier> & { loading: boolean } = {
        ...{
            id: 0,
            name: "",
            email: "",
            abn: "",
            phone: "",
            address: "",
            loading: false
        },
        ...supplier
    };
    const [formState, setFormState] = useState<Partial<supplier> & { loading: boolean }>({ ...initValues });
    const { state, setState } = useGlobalState();
    const [items, setItems] = useState<Required<{ id: number; sku: string }>[]>([]);
    const [selItem, setSelItem] = useState<any | null>(null);
    let autocompleteRender = 1;

    useEffect(() => {
        async function getItems() {
            try {
                let items: item[] = (await axios.get<item[]>("/api/items/all")).data;
                items.sort((a, b) => {
                    if (a.sku < b.sku) return -1;
                    if (a.sku > b.sku) return 1;
                    return 0;
                });
                items = items.filter(x => !supplier?.items?.find(y => y.id === x.id));
                setItems(items);
            } catch (error) {
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading items",
                        type: toastType.Error
                    })
                );
                console.error(error);
            }
        }

        getItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleClose(update: any) {
        if (!update) return closeDialog(false);
        setFormState({
            ...formState,
            loading: true
        });
        try {
            delete update.loading;
            let item = await axios.put(`/api/suppliers/${supplier?.id}`, update);
            setState(
                AddToToast(state, {
                    title: "Success",
                    description: "Updated Supplier",
                    type: toastType.Success
                })
            );
            closeDialog(item.data);
        } catch (error) {
            console.error(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Couldn't Update Supplier",
                    type: toastType.Error
                })
            );
            closeDialog(false);
        }
    }

    const formValidation = Yup.object().shape({
        name: Yup.string().required("Required"),
        email: Yup.string().email().required("Required"),
        abn: Yup.string(),
        phone: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
        post_code: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        state: Yup.string().required("Required"),
        contact: Yup.string().nullable(),
        note: Yup.string().nullable()
    });

    return (
        <React.Fragment>
            {formState.loading ? <Loader onTop={true} /> : ""}
            <DialogTitle>Edit Supplier</DialogTitle>
            <Formik initialValues={initValues} onSubmit={(values: any) => handleClose(values)} validationSchema={formValidation}>
                {(props: { values: Partial<supplier>; [key: string]: any }) => {
                    const { values, touched, errors, isSubmitting, handleChange, handleSubmit, setFieldValue, handleBlur } = props;

                    const addItem = (event: any, val: Required<{ id: number; sku: string }> | null) => {
                        if (!val) return;
                        console.log(event);
                        setSelItem(null);
                        autocompleteRender += 1;
                        if (!values.items) {
                            setFieldValue("items", [val]);
                        } else if (!values.items?.find(x => x.id === val?.id)) {
                            let tmpItems = [...values.items, val];
                            tmpItems.sort((a, b) => {
                                if (a.sku < b.sku) return -1;
                                if (a.sku > b.sku) return 1;
                                return 0;
                            });
                            setFieldValue("items", tmpItems);
                        }
                        let tmpItems = items.filter(x => x.id !== val.id);
                        tmpItems.sort((a, b) => {
                            if (a.sku < b.sku) return -1;
                            if (a.sku > b.sku) return 1;
                            return 0;
                        });
                        setItems(tmpItems);
                        setTimeout(() => {
                            document.getElementById("itemAutocomplete")?.blur();
                        }, 300);
                    };

                    const handleDelete = (itemToDelete: Required<{ id: number; sku: string }>) => () => {
                        setFieldValue(
                            "items",
                            values.items?.filter(x => x.id !== itemToDelete.id)
                        );
                        let tmpItems = [...items, itemToDelete];
                        tmpItems.sort((a, b) => {
                            if (a.sku < b.sku) return -1;
                            if (a.sku > b.sku) return 1;
                            return 0;
                        });
                        setItems(tmpItems);
                    };

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <TextField onBlur={handleBlur} autoFocus margin="dense" name="name" value={values.name} onChange={handleChange} helperText={errors.name && touched.name && errors.name} error={!!errors.name && !!touched.name} label="Name" type="text" fullWidth />
                                <TextField onBlur={handleBlur} margin="dense" name="email" value={values.email} onChange={handleChange} helperText={errors.email && touched.email && errors.email} error={!!errors.email && !!touched.email} label="Email" type="text" fullWidth />
                                <TextField onBlur={handleBlur} margin="dense" name="abn" value={values.abn} onChange={handleChange} helperText={errors.abn && touched.abn && errors.abn} error={!!errors.abn && !!touched.abn} label="ABN" type="text" fullWidth />
                                <TextField onBlur={handleBlur} margin="dense" name="phone" value={values.phone} onChange={handleChange} helperText={errors.phone && touched.phone && errors.phone} error={!!errors.phone && !!touched.phone} label="Phone" type="text" fullWidth />
                                <TextField onBlur={handleBlur} margin="dense" name="address" value={values.address} onChange={handleChange} helperText={errors.address && touched.address && errors.address} error={!!errors.address && !!touched.address} label="Street Address" type="text" fullWidth />
                                <TextField onBlur={handleBlur} margin="dense" name="city" value={values.city} onChange={handleChange} helperText={errors.city && touched.city && errors.city} error={!!errors.city && !!touched.city} label="City/Town" type="text" fullWidth />
                                <TextField onBlur={handleBlur} margin="dense" name="state" value={values.state} onChange={handleChange} helperText={errors.state && touched.state && errors.state} error={!!errors.state && !!touched.state} label="State" type="text" fullWidth />
                                <TextField
                                    onBlur={handleBlur}
                                    margin="dense"
                                    name="post_code"
                                    value={values.post_code}
                                    onChange={handleChange}
                                    helperText={errors.post_code && touched.post_code && errors.post_code}
                                    error={!!errors.post_code && !!touched.post_code}
                                    label="Post Code"
                                    type="text"
                                    fullWidth
                                />
                                <br />
                                <TextField onBlur={handleBlur} margin="dense" name="contact" value={values.contact} onChange={handleChange} helperText={errors.contact && touched.contact && errors.contact} error={!!errors.contact && !!touched.contact} label="Contact Name" type="text" fullWidth />
                                <TextField onBlur={handleBlur} margin="dense" name="note" value={values.note} onChange={handleChange} helperText={errors.note && touched.note && errors.note} error={!!errors.note && !!touched.note} label="Supplier Note" type="text" fullWidth />
                                <br />
                                <br />
                                <h4>Items:</h4>
                                {values.items ? (
                                    <div className="chips">
                                        {values.items?.map(item => {
                                            return <Chip key={item.id} label={item.sku} onDelete={handleDelete(item)} variant="outlined" size="small" />;
                                        })}
                                    </div>
                                ) : (
                                    ""
                                )}

                                <Autocomplete
                                    key={autocompleteRender}
                                    options={items}
                                    // getOptionSelected={(option, value) => option.id === value.id ?? ""}
                                    getOptionLabel={(option: Required<{ id: number; sku: string }> & { description?: string }) => option.sku + " - " + option.description}
                                    id="itemAutocomplete"
                                    openOnFocus
                                    value={selItem}
                                    onChange={addItem}
                                    style={{ width: 300 }}
                                    renderInput={(params: any) => <TextField {...params} label="SKU" />}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button variant="contained" color="secondary" disabled={isSubmitting} type="submit">
                                    Update
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}
