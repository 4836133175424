import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface confirmInfo {
    header: string;
    text: string;
    confirmText: string;
    cancelText: string;
    closeFunction: Function;
}

export function Confirmation(props: { info: confirmInfo; isOpen: boolean }) {
    const { header, text, confirmText, cancelText, closeFunction } = props.info;
    const isOpen = props.isOpen;

    return (
        <Dialog open={isOpen} onClose={() => closeFunction(false)}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>{text}</DialogContent>
            <DialogActions>
                <Button onClick={() => closeFunction(false)} color="primary">
                    {cancelText}
                </Button>
                <Button variant="contained" color="secondary" onClick={() => closeFunction(true)}>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
